import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline, Box, Slide, Alert, Snackbar } from '@mui/material';
import GlobalTip from '../providers/GlobalTip';
import TopAppBar from '../components/layout/TopAppBar';
import LeftNav from '../components/layout/LeftNav';
import Request from "../services/Request";


const drawerWidth = 80;

function MessageTransitionDown(props) {
	return <Slide {...props} direction="down" />;
}

function Layout(props) {
	let globalTipContext = GlobalTip.useMessage();
	const [navOpen, setNavOpen] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);
	const [bannerContent, setBannerContent] = React.useState('');
	const contentRef = useRef(null);

	const cssInJs = {
		root: {
			minHeight: '100vh',
			display: 'flex',
			flexDirection: 'column',
			// Target header element when alert is shown
			'& header': {
				top: showAlert ? '50px !important' : '0'
			},
			// Target nav with specific MUI classes
			'& nav.MuiBox-root div div.MuiPaper-root': {
				marginTop: showAlert ? '100px' : '50px'
			},
			// Target nav with specific MUI classes
			'& main.MuiBox-root': {
				marginTop: showAlert ? '115px' : '50px',
				marginLeft: '20px',
				marginRight: '20px'
			},
			// Center alert text in the middle of the screen
			'& #message-alert-global': {
				textAlign: 'center',
				color: 'black',
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				position: 'fixed', // Make the alert fixed
				top: 0, // Position it at the top
				zIndex: 1201 // Ensure it is above other elements
			},
			// remove text decoration from anchor tag but add underline
			'& #message-alert-content-global a': {
				textDecoration: 'none',
				borderBottom: '1px solid',
				color: 'inherit'
			}
		},
		'& #message-alert-content-global': {
			width: '100%',
			backgroundColor: '#fff',
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			zIndex: 1201,
			display: 'flex',
			justifyContent: 'center'
		},
		appContainer: (showAlert) => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1
		}),
		appBar: (showAlert) => ({
			position: 'fixed',
			top: showAlert ? '50px !important' : 0
		}),
		leftNav: (showAlert) => ({
			'& .MuiPaper-root': {
				marginTop: showAlert ? '100px' : 0
			}
		}),
		contentSection: (showAlert) => ({
			flexGrow: 1,
			px: 2,
			mt: "50px"
		})
	};

	const handleDrawerToggle = () => {
		setNavOpen(!navOpen);
	};

	React.useEffect(() => {
		// Replace with your actual API call
		const checkUserStatus = async () => {
			Request.get('/api/banner-status', {credentials: 'include'}, true).then(response => {
				const data = response.data

				setShowAlert(data.shouldShowAlert);
				if (data.shouldShowAlert) {
					console.log(data.banner.content)
					setBannerContent(data.banner.content);
				}
			}).catch(error => {
				console.error('Error checking user status:', error);
				setShowAlert(false);
			});
		};

		checkUserStatus();
	}, []);

	useEffect(() => {
		if (contentRef.current) {
			// Ensure the link has the correct attributes
			const sanitizedContent = bannerContent.replace(
				/<a /g,
				'<a target="_blank" rel="noopener noreferrer" '
			);
			contentRef.current.innerHTML = sanitizedContent;
		}
	}, [bannerContent]);

	return (
		<Box sx={{ ...cssInJs.root, showAlert }}>
			<CssBaseline />
			{showAlert && (
				<Box sx={cssInJs.alert}>
					<Alert id={'message-alert-global'} severity="warning">
						<div id={'message-alert-content-global'} ref={contentRef} />
					</Alert>
				</Box>
			)}
			<Box sx={cssInJs.appContainer}>
				<TopAppBar id={'app-bar-global'}
				   width={drawerWidth}
				   sx={cssInJs.appBar}
				/>
				<Box sx={{ display: 'flex', flexGrow: 1 }}>
					<LeftNav id={'left-nav-global'}
						width={drawerWidth}
						navOpen={navOpen}
						onNavClose={handleDrawerToggle}
						sx={cssInJs.leftNav}
					/>
					<Box component="main" sx={{
						...cssInJs.contentSection,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
						<Outlet />
					</Box>
				</Box>
			</Box>

			<Snackbar open={globalTipContext.messageOpen} TransitionComponent={MessageTransitionDown} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert variant="filled" severity="warning" sx={{ width: '100%' }}>
					{globalTipContext.messageContent}
				</Alert>
			</Snackbar>
			<Snackbar open={globalTipContext.successOpen} TransitionComponent={MessageTransitionDown} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert variant="filled" severity="success" sx={{ width: '100%' }}>
					{globalTipContext.successContent}
				</Alert>
			</Snackbar>
		</Box>
	);
}

export default Layout;