import AuthContext from '../../providers/AuthProvider';
import { useState, useEffect } from 'react';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import ClientService from '../../services/ClientService';
import TipProvider from '../../providers/GlobalTip';
import EmailTemplate from '../../templates/email/beta-client-user-email.template';
import { Box, Button, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';

async function buildContent({ loomVideoLink, title, bodyText }) {
    try {
        let _baseTemplate = EmailTemplate;

        // Replace the placeholders with the actual content
        let content = _baseTemplate.replace('{{LOOM_VIDEO_LINK}}', loomVideoLink);
        content = content.replace('{{TITLE}}', title);
        content = content.replace('{{BODY_TEXT}}', bodyText);

        return content;
    } catch (error) {
        console.error('Failed to build email content:', error);
        throw new Error('Failed to build email content');
    }
}

const EmailClientUsers = () => {
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [contentType, setContentType] = useState('simple');
    const [loomVideoLink, setLoomVideoLink] = useState('');
    const [title, setTitle] = useState('');
    const [bodyText, setBodyText] = useState('');
    const currentClientContext = CurrentClientContext.useClient();
    const tip = TipProvider.useMessage();

    const auth = AuthContext.useAuth();

    useEffect(() => {
        document.title = 'Retail ORBIT: Email Client Users';
    }, []);

    const sendEmailToClientUsers = async ({ preview = false }) => {
        if (!currentClientContext.client) {
            tip.show('Client not found');
            return;
        }

        let _content = content;
        if (contentType === 'simple') {
            try {
                _content = await buildContent({ loomVideoLink, title, bodyText });
            } catch (error) {
                tip.show(error.message);
                return;
            }
        }

        let previewEmail = null;
        if (preview) {
            previewEmail = auth.user?.email;
            if (!previewEmail) {
                tip.show('User email not found for preview');
                return;
            }
        }

        try {
            await ClientService.emailClientUsers({ clientCode: currentClientContext.client.ClientCode, clientId: currentClientContext.client.Id, subject, content: _content, previewEmail: previewEmail });

            tip.showSuccess('Email sent successfully');
        } catch (error) {
            tip.show(error.response?.data?.message ?? 'Failed to send email');
        }
    };

    return (
        <Box>
            <Typography variant="h3">Email Client Users</Typography>
            <Typography
                variant="body1"
                sx={{
                    marginBottom: 2,
                    borderBottom: '1px solid #ccc',
                }}
            >
                Send an email with given subject and content to all "Client" type users for the currently selected client.
                <br/>
                Preview will send the email to just your own email address for review. Send will send it to all users for the client.
                <br/>
                Content should be in HTML format, and can be built with any online email template builder.
            </Typography>
            <Box sx={{marginBottom: 2}}>
                <TextField
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    fullWidth
                />
            </Box>
            <Box sx={{marginBottom: 2}}>
                <Select
                    value={contentType}
                    onChange={(e) => setContentType(e.target.value)}
                    fullWidth
                >
                    <MenuItem value="simple">Simple</MenuItem>
                    <MenuItem value="full">Enter Full Template</MenuItem>
                </Select>
            </Box>
            {contentType === 'full' ?
                <Box>
                    <TextField
                        label="Content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                    />
                </Box>
            : <>
                <Box>
                    <TextField
                        label="Loom Video Link"
                        value={loomVideoLink}
                        onChange={(e) => setLoomVideoLink(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box>
                    <TextField
                        label="Body Text"
                        value={bodyText}
                        onChange={(e) => setBodyText(e.target.value)}
                        fullWidth
                    />
                </Box>
            </>}
            <Box>
                <Button onClick={() => sendEmailToClientUsers({ preview: true })}>Preview</Button>
                <Button onClick={() => sendEmailToClientUsers({ preview: false })}>Send</Button>
            </Box>
        </Box>
    );
}

export default EmailClientUsers;