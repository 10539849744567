import React, { createContext, useContext, useState, useEffect } from 'react';

const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
    const [surveyCompleted, setSurveyCompleted] = useState(false);
    const [surveyClosed, setSurveyClosed] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        try {
            const currentUser = JSON.parse(localStorage.getItem('current_user'));
            if (currentUser && currentUser.id) {
                const id = currentUser.id;
                setUserId(id);

                let surveyStatus = localStorage.getItem(`surveyCompleted_${id}`);
                let surveyClosedStatus = localStorage.getItem(`surveyClosed_${id}`);
                if (surveyStatus === null) { // first time initialization
                    localStorage.setItem(`surveyCompleted_${id}`, 'false');
                    surveyStatus = 'false';
                }
                if (surveyClosedStatus === null) { // first time initialization
                    localStorage.setItem(`surveyClosed_${id}`, 'false');
                    surveyClosedStatus = 'false';
                }

                // Update state based on localStorage values
                setSurveyCompleted(surveyStatus === 'true');
                setSurveyClosed(surveyClosedStatus === 'true');
            }
        } catch (error) {
            console.error("Error reading user information:", error);
        }
    }, []);

    const handleSurveySubmit = () => {
        if (!userId) return;

        try {
            // If TypeForm submission is successful, set survey as completed
            localStorage.setItem(`surveyCompleted_${userId}`, 'true');
            setSurveyCompleted(true);
        } catch (error) {
            console.error('Error submitting TypeForm survey:', error);
            // Even if there is an error, set survey as completed, because it was sent to typeform
            // There is no way to know if the user actually completed the survey on our side
            localStorage.setItem(`surveyCompleted_${userId}`, 'true');
            setSurveyCompleted(true);
        }
    };

    const handleClose = () => {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        let userId = null;
        if (currentUser && currentUser.id) {
            userId = currentUser.id;
            setUserId(userId);
        }
        if (!userId){
            console.log("No user id found");
            return;
        }

        localStorage.setItem(`surveyClosed_${userId}`, 'true');
        setSurveyClosed(true);
    };

    const handleShowSurvey = () => {
        if (!userId){
            return;
        }
        localStorage.setItem(`surveyClosed_${userId}`, 'false');
        setSurveyClosed(false);
    };

    return (
        <SurveyContext.Provider value={{ surveyCompleted, surveyClosed, handleSurveySubmit, handleClose, handleShowSurvey }}>
            {children}
        </SurveyContext.Provider>
    );
};

export const useSurvey = () => useContext(SurveyContext);