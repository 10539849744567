import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';

const SimpleSearchableClientDropdown = ({ clients, selectedClient, setSelectedClient, styleOverrides = {} }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSelectClient = (e) => {
        const _selectedClient = clients.find((client) => client.ClientCode === e.target.value);
        setSelectedClient(_selectedClient);
    };

    return (
        <FormControl
            fullWidth
            variant="outlined"
            sx={styleOverrides}
        >
            <InputLabel>Client to Add</InputLabel>
            <Select
                MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                        sx: {
                            maxHeight: 300,
                            mt: 1,
                        },
                    },
                }}
                onChange={handleSelectClient}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                value={selectedClient?.ClientCode || ''}
                onClose={() => setSearchQuery('')}
                renderValue={() => selectedClient?.ClientCode || ''}
            >
                <ListSubheader>
                    <TextField
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        autoFocus
                        placeholder="Search"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        }}
                        onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation(); // don't allow default select behavior
                            }
                        }}
                    />
                </ListSubheader>
                {clients
                    .filter((client) => client.ClientCode.toLowerCase().includes(searchQuery.toLowerCase()) || (!client.ClientCode.toLowerCase().includes(searchQuery.toLowerCase()) && client.Name.toLowerCase().includes(searchQuery.toLowerCase())))
                    .map((client) => (
                        <MenuItem key={client.Id} value={client.ClientCode}>
                            {client.Name} ({client.ClientCode})
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}

export default SimpleSearchableClientDropdown;
