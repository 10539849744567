import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Box,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {useNavigate, useLocation} from "react-router-dom";
import _ from 'underscore'
import PlanService from '../../services/PlanService';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import ModifyStyle from '../../components/plan/PlanStyle'
import LivePlanEdit from '../../components/plan/LivePlanEdit'
import TipContext from '../../providers/GlobalTip'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { handleTrackError } from '../../services/AmplitudeService';
import ClientService from '../../services/ClientService';
import AuthProvider from '../../providers/AuthProvider';

const serviceTypeMapping = {
    1: 'OTB',
    2: 'PNOTB',
}

const getServiceTypeMapping = (serviceTypeId) => {
    if (serviceTypeMapping[serviceTypeId]) {
        return serviceTypeMapping[serviceTypeId]
    } else {
        return 'Other'
    }
}

const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const queryParams = {};
    for (let [key, value] of params.entries()) {
        queryParams[key] = value;
    }
    return queryParams;
}

const LivePlanModify = () => {
    const location = useLocation()

    // get query params from url
    const query = getQueryParams(location.search)
    const hasLoadedInitialLocationClass = useRef(query?.lci ? false : true)

    const currentClientContext = CurrentClientContext.useClient()
    const [loading, setLoading] = useState(true);

    // active showing plan
    const [planId, setPlanId] = useState();
    const [planVersion, setPlanVersion] = useState();

    // dropdown options
    const [epochMonthOptions, setEpochMonthOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [planOptions, setPlanOptions] = useState([]);
    const [maxPlanVersion, setMaxPlanVersion] = useState();

    // selected dropdown values
    const [epochMonth, setEpochMonth] = useState(PlanService.getTodayEpochMonth());
    const [selectedPlanId, setSelectedPlanId] = useState(0);
    const [selectedLocationId, setSelectedLocationId] = useState(0);
    const [selectedLocationClassId, setSelectedLocationClassId] = useState(0);

    const [clientBasicData, setClientBasicData] = useState({})
    const [planData, setPlanData] = useState()
    const [locationClassNotFound, setLocationClassNotFound] = useState(false)
    const navigate = useNavigate()
    const messageContext = TipContext.useMessage()
    const [createFuturePlanTrigger, setCreateFuturePlanTrigger] = useState(false)
    const [futureCreated, setFutureCreated] = useState(true)
    const [removingPlan, setRemovingPlan] = useState(false)
    const isHeader = JSON.parse(localStorage.getItem('showHeaderContainer'))
    const [showHeaderContainer, setShowHeaderContainer] = useState( isHeader === null ? true : isHeader);

    const [clientList, setClientList] = useState([]);
    const [forceReload, setForceReload] = useState(false);

    const [isDev, setIsDev] = useState(false)
    const auth = AuthProvider.useAuth()

    // is admin
	useEffect(() => {
		// role-create marks admin, but we don't have role specific checks currently
		if (auth.checkPermission('dev-tools')) {
			setIsDev(true)
		}
	}, [auth])

    useEffect(() => {
		document.title = 'Retail ORBIT: Live Plan Modify'

        if (!epochMonthOptions.length && epochMonth) {
            // build out current epoch month - 12
            let _epochMonth = epochMonth
            let _epochMonthOptions = [_epochMonth + 1]
            for (let i = 0; i < 12; i++) {
                _epochMonthOptions.push(_epochMonth - i)
            }

            setEpochMonthOptions(_epochMonthOptions)
        }

        const fetchClients = async () => {
            try {
                const response = await ClientService.listClientsSimple();
                setClientList(response);
            } catch (error) {
                console.log(error);
                messageContext.show('Failed to load client list for copy from/to');
            }
        }

        fetchClients();
	}, [])

    useEffect(() => {
        localStorage.setItem('showHeaderContainer', JSON.stringify(showHeaderContainer));
    }, [showHeaderContainer]);

    const getClientLivePlans = async () => {
        try {
            let basicInfoV2 = await PlanService.getClientLivePlans(currentClientContext.client.Id, epochMonth ?? null)

            // if basicInfoV2 is empty, and epoch month is not equal to today epoch month, reset
            if ((Array.isArray(basicInfoV2) && basicInfoV2.length === 0) && epochMonth != PlanService.getTodayEpochMonth()) {
                setEpochMonth(PlanService.getTodayEpochMonth())
                messageContext.show('No plans found for selected epoch month. Resetting to current epoch month.')
                return
            }

            setClientBasicData(basicInfoV2)
        } catch(e) {
            if (e.response?.data?.message)
                messageContext.show(e.response?.data.message)
            else
                messageContext.show('Failed to load client live plans')
            handleTrackError({ error: e, pageName: 'Live Plan', overrideMessage: 'Failed to load client live plans', additionalMetadata: { clientCode: currentClientContext.client?.ClientCode } })
        }
    }

    useEffect(async () => {
        if( currentClientContext.client ) {
            try {
                getClientLivePlans()
            }
            catch(e) {
                console.log(e)
            }
        }
    }, [currentClientContext.client])

    useEffect(() => {
        setPlanId(null)
        setPlanVersion(null)
        setPlanOptions([])
        setClassOptions([])
        setLocationOptions([])
        setClientBasicData({})
        setSelectedLocationId(0)
        setSelectedLocationClassId(0)
        setSelectedPlanId(0)

        if (currentClientContext.client) {
            getClientLivePlans()
        }
    }, [epochMonth])

    function buildLocationOptions(overrideSelect = false) {
        let _locationOptions = []

        if( clientBasicData ) {
            Object.entries(clientBasicData).map(([key, item]) => {
                _locationOptions.push({
                    location_id: item.location_id,
                    name: item.location_name,
                    code: item.location_code,
                })
            })
        } else {
            return
        }

        // sort by location code
        _locationOptions.sort((a, b) => {
            if (a.code < b.code) {
                return -1
            }
            if (a.code > b.code) {
                return 1
            }
            return 0
        })

        setLocationOptions(_locationOptions)

        if (!hasLoadedInitialLocationClass.current && _locationOptions.length > 0) {
            if (!query?.lci) {
                hasLoadedInitialLocationClass.current = true
            }

            // search through client basic data and find location where has location class id = query.lci
            let foundLocation = Object.entries(clientBasicData).find(([key, item]) => {
                return Object.entries(item.classes).find(([key, item]) => {
                    return key == query?.lci
                })
            })

            foundLocation = foundLocation ? foundLocation[1] : null

            if (foundLocation) {
                overrideSelect = foundLocation.location_id
            }
        }

        if (overrideSelect && _locationOptions.find(item => item.location_id == overrideSelect)) {
            setSelectedLocationId(overrideSelect)
        } else {
            // select first
            if (_locationOptions.length > 0) {
                setSelectedLocationId(_locationOptions[0].location_id)
            }
        }
    }

    useEffect(() => {
        buildLocationOptions(selectedLocationId)
    }, [clientBasicData])

    function buildClassOptions(overrideSelect = false) {
        let _classOptions = []
        if (selectedLocationId) {
            const location = clientBasicData[selectedLocationId]
            if (location) {
                Object.entries(location.classes).map(([key, item]) => {
                    _classOptions.push({
                        class_id: key,
                        name: item.class_name,
                        code: item.class_code,
                    })
                })
            }
        }

        // sort by code
        _classOptions = _classOptions.sort((a, b) => {
            if (a.code < b.code) {
                return -1
            }
            if (a.code > b.code) {
                return 1
            }
            return 0
        })

        
        // save current class code
        let currentClassCode = classOptions.find(item => item.class_id == selectedLocationClassId)?.code

        if (!hasLoadedInitialLocationClass.current && _classOptions.length > 0) {
            if (!query?.lci) {
                hasLoadedInitialLocationClass.current = true
            }

            overrideSelect = query?.lci

            hasLoadedInitialLocationClass.current = true
        }

        setClassOptions(_classOptions)

        if (overrideSelect && _classOptions.find(item => item.class_id == overrideSelect)) {
            setSelectedLocationClassId(overrideSelect)
        } else {
            // if class id is in new location, set it
            let newLocationClass = _classOptions.find(item => item.code == currentClassCode)

            if (newLocationClass) {
                setSelectedLocationClassId(newLocationClass.class_id)
            } else {
                if (currentClassCode) {
                    // let user know
                    messageContext.show('Class not found in new location. Selecting first if available.')
                }

                if (_classOptions.length > 0) {
                    setSelectedLocationClassId(_classOptions[0].class_id)
                }
            }
        }

        setPlanOptions([])
    }

    useEffect(() => {
        buildClassOptions()
    }, [selectedLocationId])

    const buildPlanOptions = (overrideSelectMax) => {
        let _planOptions = []

        if (selectedLocationClassId) {
            const locationClass = clientBasicData[selectedLocationId]?.classes[selectedLocationClassId]
            if (locationClass) {
                Object.entries(locationClass.plan_versions).map(([key, item]) => {
                    _planOptions.push({
                        plan_id: item.plan_id,
                        plan_version: item.plan_version,
                        service_type_id: item.service_type_id,
                    })
                })
            }
        }

        setPlanOptions(_planOptions)

        if (_planOptions.length > 0) {
            // find max plan id
            let maxPlanId = 0

            Object.entries(_planOptions).map(([key, item]) => {
                if (item.plan_id > maxPlanId && item.plan_version < 1000) { // exclude future plans
                    maxPlanId = item.plan_id
                }
            })

            setMaxPlanVersion(_planOptions.find(plan => plan.plan_id === maxPlanId)?.plan_version)

            if (overrideSelectMax && _planOptions.find(plan => plan.plan_id === overrideSelectMax)) {
                setSelectedPlanId(overrideSelectMax)
                setPlanId(overrideSelectMax)
                setPlanVersion(_planOptions.find(plan => plan.plan_id === overrideSelectMax).plan_version)
            } else if (maxPlanId) {
                setSelectedPlanId(maxPlanId)
                setPlanId(maxPlanId)
                setPlanVersion(_planOptions.find(plan => plan.plan_id === maxPlanId).plan_version)
            }
        }
    }

    useEffect(() => {
        buildPlanOptions()
    }, [selectedLocationClassId])

    const createFuturePlan = () => {
        setFutureCreated(false)
        setCreateFuturePlanTrigger(true)
        setTimeout(() => {
            setCreateFuturePlanTrigger(false)
        }, 1000)
    }

    const removeFuturePlan = async (plan) => {
        setRemovingPlan(true)
        try {
            const last_plan = await PlanService.removePlan(currentClientContext.client.Id, plan.plan_id, plan.plan_version)
            setRemovingPlan(false)
            navigateToPlanVersion(planData?.versions[1].plan_id, planData?.versions[1].version)
        } catch(e) {
            setRemovingPlan(false)
            messageContext.show(e.response?.data.message)
            handleTrackError({ error: e, pageName: 'Live Plan', overrideMessage: 'Failed to remove future plan', additionalMetadata: { clientCode: currentClientContext.client?.ClientCode } })
        }
    }

    const addPlanVersionToClassDropdown = (planId, planVersion, locationId, locationClassId, serviceTypeId) => {
        try {
            // round plan version and plan id to nearest 10th
            planVersion = Math.round(planVersion * 10) / 10;

            let _clientBasicData = {...clientBasicData}
            let _location = _clientBasicData[locationId]
            let _locationClass = _location['classes'][locationClassId]
            let _planVersions = _locationClass['plan_versions']
            _planVersions[planId] = {
                plan_id: planId,
                plan_version: planVersion,
                service_type_id: serviceTypeId
            }
            _locationClass['plan_versions'] = _planVersions
            _location['classes'][locationClassId] = _locationClass
            _clientBasicData[locationId] = _location
            setClientBasicData(_clientBasicData)
            buildPlanOptions(planId)

            // load new version
            setPlanId(planId)
            setPlanVersion(planVersion)
            buildLocationOptions(locationId)
            buildClassOptions(locationClassId)
            buildPlanOptions(planId)
        } catch(e) {
            console.log(e)
        }
    }

    const onFutureCreated = () => {
        setFutureCreated(true)
    }

    const nextClass = ({ forward=true }) => {
        // get current class
        let currentClassIndex = classOptions.findIndex(item => item.class_id == selectedLocationClassId)
        if (currentClassIndex === -1) {
            return
        }

        let nextClassIndex = forward ? currentClassIndex + 1 : currentClassIndex - 1
        if (nextClassIndex < 0) {
            nextClassIndex = classOptions.length - 1
        } else if (nextClassIndex >= classOptions.length) {
            nextClassIndex = 0
        }

        setSelectedLocationClassId(classOptions[nextClassIndex].class_id)
    }

    const nextLocation = ({ forward=true }) => {
        // get current location
        let currentLocationIndex = locationOptions.findIndex(item => item.location_id == selectedLocationId)
        if (currentLocationIndex === -1) {
            return
        }

        let nextLocationIndex = forward ? currentLocationIndex + 1 : currentLocationIndex - 1
        if (nextLocationIndex < 0) {
            nextLocationIndex = locationOptions.length - 1
        } else if (nextLocationIndex >= locationOptions.length) {
            nextLocationIndex = 0
        }

        setSelectedLocationId(locationOptions[nextLocationIndex].location_id)
    }

    if (!clientBasicData || Object.keys(clientBasicData).length === 0) {
        return null
    }

    return <Box sx={ModifyStyle.mainContainer}>
         <Box sx={ModifyStyle.filterHeader}>
            <Box style={{...ModifyStyle.alignCenter,...ModifyStyle.textFields}}>
                <Box sx={ModifyStyle.textStyle}>
                    <Box
                        component="select"
                        sx={{
                            mx: 0.5,
                            height: '30px',
                            backgroundColor: 'transparent',
                            color: 'white',
                            fontWeight: 'bold',
                            borderColor: 'rgba(255,255,255,0.6)',
                            'option': {
                                backgroundColor: '#5c4174!important',
                            }
                        }}
                        onChange={e => {
                            setEpochMonth(e.target.value)
                        }}
                        value={epochMonth}
                    >
                        {epochMonthOptions ? epochMonthOptions.map((item, key) => {
                            return <option key={`epoch_month_item_${item}`} value={item}>{PlanService.getEpochMonthAsMMMYY(item)}</option>
                        }) : null}
                    </Box>
                </Box>
                <Box sx={ModifyStyle.textStyle}>
                    <Box
                        component="select"
                        sx={{
                            mx: 0.5,
                            height: '30px',
                            backgroundColor: 'transparent',
                            color: 'white',
                            fontWeight: 'bold',
                            borderColor: 'rgba(255,255,255,0.6)',
                            'option': {
                                backgroundColor: '#5c4174!important',
                            }
                        }}
                        onChange={e => {
                            setSelectedLocationId(e.target.value)
                        }}
                        value={selectedLocationId}
                    >
                        <option value={0}>Select Location</option>
                        {locationOptions ? locationOptions.map((item, key) => {
                            return <option key={`location_item_${item.location_id}`} value={item.location_id}>{item.name} - {item.code}</option>
                        }) : null}
                    </Box>
                </Box>
                {selectedLocationId ?
                    <Box sx={ModifyStyle.textStyle}>
                        <Box
                            component="select"
                            sx={{
                                mx: 0.5,
                                height: '30px',
                                backgroundColor: 'transparent',
                                color: 'white',
                                fontWeight: 'bold',
                                borderColor: 'rgba(255,255,255,0.6)',
                                'option': {
                                    backgroundColor: '#5c4174!important',
                                }
                            }}
                            onChange={e => {
                                setSelectedLocationClassId(e.target.value)
                            }}
                            value={selectedLocationClassId}
                        >
                            <option value={0}>Select Class</option>
                            {classOptions ? classOptions.map((item, key) => {
                                return <option key={`class_item_${item.class_id}`} value={item.class_id}>{item.name} - {item.code}</option>
                            }) : null}
                        </Box>
                    </Box>
                : null}
                {planOptions && planOptions.length ?
                    <Box sx={ModifyStyle.textStyle}>
                        <>
                            {(selectedPlanId && selectedPlanId != 0) ? `${selectedPlanId} - ` : ''}
                            <Box
                                component="select"
                                sx={{
                                    mx: 0.5,
                                    height: '30px',
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    borderColor: 'rgba(255,255,255,0.6)',
                                    'option': {
                                        backgroundColor: '#5c4174!important',
                                    }
                                }}
                                onChange={(e) => {
                                    setSelectedPlanId(e.target.value)
                                }}
                                value={selectedPlanId}
                            >
                                <option value={0}>Select Version</option>
                                {planOptions && planOptions.map((item) => {
                                    return <option key={`plan_item_${item.plan_id}`} value={item.plan_id}>{item.plan_version} {item.plan_version == maxPlanVersion ? '*' : ''}</option>
                                })}
                            </Box>
                            {(selectedPlanId && selectedPlanId != 0) ? ` - ${getServiceTypeMapping(planOptions.find(plan => plan.plan_id === selectedPlanId)?.service_type_id)}` : ''}
                        </>
                    </Box>
                : null}
                <Box sx={ModifyStyle.textStyle}>
                    <Button
                        variant="outlined"
                        endIcon={<NavigateNextOutlinedIcon />}
                        sx={{
                            color: 'white',
                        }}
                        onClick={(e) => {
                            if (selectedPlanId && selectedPlanId != 0 && selectedLocationId && selectedLocationClassId) {
                                setPlanId(selectedPlanId)
                                setPlanVersion(planOptions[selectedPlanId]?.plan_version)
                                setForceReload(true)
                            } else {
                                messageContext.show('Please select all fields')
                            }
                        }}
                    >
                        Go
                    </Button>
                </Box>
            </Box>
         </Box>
         {isDev ? 
            <Box sx={{...ModifyStyle.alignCenter, width: '100%' }}>
                <Typography variant="h6" sx={{color: 'blaack', fontWeight: 'bold', fontSize: '0.8rem', textAlign: 'center', margin: 'auto' }}>
                    {currentClientContext.client?.Name} - {currentClientContext.client?.ClientCode} - ID: {currentClientContext.client?.Id}
                    <br />
                    Loc ID: {selectedLocationId} - CLC ID: {selectedLocationClassId} - Plan ID: {selectedPlanId}
                    <br />
                    SELECT * FROM `planbuilderv2.plan_settings` WHERE plan_settings_id = '{selectedPlanId}' AND client_id = {currentClientContext.client?.Id};
                    <br />
                    SELECT * FROM `planbuilderv2.next_12_months_plan` WHERE plan_settings_id = '{selectedPlanId}' AND client_id = {currentClientContext.client?.Id} ORDER BY plan_epoch_month DESC;
                </Typography>
            </Box>
        : null}
         <Box sx={ModifyStyle.mainSection}>
            {!loading && locationClassNotFound && <Box sx={ModifyStyle.loadingSection}>
                <GppMaybeOutlinedIcon sx={{ fontSize: 50 }} />
                This Location-Class not found
            </Box>}
            {planId && currentClientContext.client &&
                <LivePlanEdit
                    client={currentClientContext.client}
                    epochMonth={epochMonth}
                    // Replace with epochMonth when select is available
                    onPlanDataLoad={e => {
                        setPlanData(e)
                        onFutureCreated()
                    }}
                    planId={planId}
                    isFuturePlan={selectedPlanId ? planOptions.find(plan => plan.plan_id === selectedPlanId)?.is_future_plan : false}
                    planVersion={planVersion}
                    locationClassId={selectedLocationClassId}
                    createFuturePlan={createFuturePlan}
                    removeFuturePlan={removeFuturePlan}
                    onFutureCreated={e => onFutureCreated()}
                    showHeaderContainer={showHeaderContainer}
                    setShowHeaderContainer={setShowHeaderContainer}
                    addPlanVersionToClassDropdown={addPlanVersionToClassDropdown}
                    nextClass={nextClass}
                    nextLocation={nextLocation}
                    clientList={clientList}
                    forceReload={forceReload}
                    setForceReload={setForceReload}
                />
            }
         </Box>
    </Box>
}

export default LivePlanModify;
