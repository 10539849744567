import React, { useState, useEffect } from "react";
import { AgChartsReact } from "ag-charts-react";

// React Chart Component
const PlanChart = (props) => {
  const salesPlan = props.chartData.get("SalesPlan") ?? [];
  const inventoryBom = props.chartData.get("InventoryBomPlan") ?? [];
  const markdownPlan = props.chartData.get("MarkdownsPlan") ?? [];
  const invInfPlanRetail = props.chartData.get("InflowPlanRetail") ?? [];

  const getData = () => {
    return props.displayMonths.map((month, index) => ({
      month: month,
      Sales: salesPlan[index] ? salesPlan[index] / 1000 : 0,
      Inventory: inventoryBom[index] ? inventoryBom[index] / 1000 : 0,
      invInfPlanRetail: invInfPlanRetail[index] ? invInfPlanRetail[index] / 1000 : 0,
      Markdowns: markdownPlan[index] ? markdownPlan[index] / 1000 : 0,
    }));
  };

  const [options, setOptions] = useState({
    data: getData(),
    series: [
      {
        type: "line",
        xKey: "month",
        yKey: "Sales",
        yName: "Sales Plan",
        marker: {
          fill: "#008000", // Green fill
          stroke: "#008000", // Green
        },
        stroke: "#008000", // Green
      },
      {
        type: "area",
        xKey: "month",
        yKey: "Inventory",
        yName: "Inventory BOM",
        fill: "yellow", // Orange
        fillOpacity: 0.5,
      },
      {
        type: "line",
        xKey: "month",
        yKey: "invInfPlanRetail",
        yName: "Plan Inf Retail",
        marker: {
          fill: "#800080", // Green fill
          stroke: "#800080",
        },
        stroke: "#800080",
      },
      {
        type: "line",
        xKey: "month",
        yKey: "Markdowns",
        yName: "Markdowns Plan",
        marker: {
          fill: "#FF0000", // Green fill
          stroke: "#FF0000", // Red
        },
        stroke: "#FF0000", // Red
      },
    ],
    axes: [
        {
          type: "category",
          position: "bottom",
          label: {
            rotation: 45,
          },
        },
        {
          type: "number",
          position: "left",
          label: {
            enabled: false,
          },
        }
      ],
      legend: {
        enabled: false,
      },
  });
  // Update chart options when 'chartData' changes
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      data: getData(),
    }));
  }, [props.chartData]);

  return (
    // AgChartsReact component with options passed as prop
    <AgChartsReact options={options} />
  );
};

export default PlanChart;
