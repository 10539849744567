import {
    useState,
    useEffect,
    useRef
} from "react";
import TableauService from "../../services/TableauService";
import UserService from "../../services/UserService";
import ClientService from "../../services/ClientService";
import CurrentClientContext from "../../providers/CurrentClientProvider";
import { Button } from "@mui/material";
import TipsContext from "../../providers/GlobalTip";

function TableauViz(props) {
    const [forceProgress, setForceProgress] = useState(false)
    const currentClientContext = CurrentClientContext.useClient();
    const [hasMultipleClients, setHasMultipleClients] = useState(false)
    const tip_context = TipsContext.useMessage();

    const [clientList, setClientList] = useState([])

    const { tableau } = window;
    const [viz, setViz] = useState(null)
    const urlProgress = "https://data.retailorbit.com/trusted/{user_auth_key}/views/ClientProgressOverview_QA/CLIENTPROGRESSOVERVIEW?:iid=1&:toolbar=top";
    const urlWide = "https://data.retailorbit.com/trusted/{user_auth_key}/views/Client-Wide_QA/CLIENT-WIDEPROGRESSOVERVIEW?:showAppBanner=false&:display_count=n&:toolbar=top&:alerts=n&:subscriptions=n&:dataDetails=n&:showShareOptions=y&:showVizHome=n&:origin=viz_share_link";
    const ref = useRef(null)

    // Init tableau view
    function initViz(vizClientList, isSingle = false) {
        TableauService.getTableauUserToken().then(async res => {
            let token = res?.token

            if (!token) return false;

            let userUrl;
            if (isSingle) {
                userUrl = urlProgress.replace('{user_auth_key}', token)
            } else {
                userUrl = urlWide.replace('{user_auth_key}', token)
            }

            let params = {
                'height': '85vh',
                'width': '100%',
                'hideTabs':false,
                'hideToolbar': false,
                'onFirstInteractive': function () {
                    _viz.getWorkbook().changeParameterValueAsync('epochMonthRM', Number(ClientService.getEpochMonthFromDate(new Date())));
                    if(isSingle){
                        _viz.getWorkbook().changeParameterValueAsync('Clientid', Number(vizClientList.toString()));
                    }
                    _viz.refreshDataAsync().then(success =>  {
                        _viz.show();
                    });

                }
            }

            dropViz()

            let _viz = new tableau.Viz(ref.current, userUrl, params);

            setViz(_viz)
        })
    }

    function dropViz() {
        if (viz) {
            viz.dispose()
        }
    }

    useEffect(() => {
        UserService.getUserClients().then(async res => {
            let clientList = res ?? 0

            setClientList(clientList)
            if (res && res.length > 1) {
                setHasMultipleClients(true)
                if (!(forceProgress && currentClientContext.client?.Id)) {
                    initViz(clientList, false)
                } else {
                    initViz([currentClientContext.client.Id], true)
                }
            } else {
                initViz(clientList, true)
            }
        })
    },[])

    useEffect(() => {
        return () => {
            // componentwillunmount in functional component.
            dropViz()
        }
    }, [])

    /**
     * Use Effect for Client Context Changing
     */
    useEffect(() => {
        if (!hasMultipleClients) {
            return;
        }
        if (!currentClientContext.client?.Id) {
            setForceProgress(false)
            dropViz()
            initViz(clientList, false)
            return;
        }
        if (forceProgress && currentClientContext.client?.Id && hasMultipleClients) {
            dropViz()
            initViz([currentClientContext.client.Id], true)
        } else {
            dropViz()
            initViz(clientList, false)
        }
    }, [currentClientContext.client])

    return (
        <>
            {(hasMultipleClients) ?
                <Button onClick={() => {
                    if (!currentClientContext.client?.Id) {
                        tip_context.show('Please select a client first')
                        return
                    }

                    if (forceProgress) {
                        setForceProgress(false)
                        dropViz()
                        initViz(clientList, false)
                        return
                    } else {
                        setForceProgress(true)
                        dropViz()
                        initViz([currentClientContext.client.Id], true)
                    }
                }}>
                    Toggle Single Client View
                </Button>
             : null}
             <br/>
            <div ref={ref}></div>
        </>
    )
}

export default TableauViz