import React, {useState} from 'react';
import Button from "@mui/material/Button";

function EncryptionTool() {
    const [clearText, setClearText] = useState('');
    const [encryptedText, setEncryptedText] = useState('');
    const [result, setResult] = useState('');

    const handleEncrypt = async () => {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/encrypt`, {
            method: 'POST',
            port: 80,
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify({clearText}),
        });
        const data = await response.json();
        setResult(data.encrypted);
    };

    const handleDecrypt = async () => {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/decrypt`, {
            method: 'POST',
            port: 80,
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify({encryptedText}),
        });
        const data = await response.json();
        setResult(data.clearText);
    };

    return (
        <div>
            <div>
                <h1>Encryption Tool</h1>
                <textarea style={{width: '100%'}} rows={4}
                          placeholder="Enter text to encrypt"
                          value={clearText}
                          onChange={(e) => setClearText(e.target.value)}
                ></textarea>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEncrypt}
                >
                    Encrypt
                </Button>
            </div>
            <div style={{margin: '20px 0'}}></div>
            <div>
            <textarea style={{width: '100%'}} rows={4}
                      placeholder="Enter text to decrypt"
                      value={encryptedText}
                      onChange={(e) => setEncryptedText(e.target.value)}
            ></textarea>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDecrypt}
                >
                    Decrypt
                </Button>
            </div>
            <div>
                <h2>Result:</h2>
                <p>{result}</p>
            </div>
        </div>
);
}

export default EncryptionTool;
