import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
	CircularProgress,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	TextField,
	InputAdornment,
	FormGroup,
	FormControlLabel,
} from '@mui/material';
import jss from '../profile/ProfileCommonJss'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LocationService from '../../../services/LocationService'
import ClientService from "../../../services/ClientService";


function ClientClassLocationList(props) {
	const {
		clientId,
		locations,
		renderLocations,
		onLocationChange,
		multiMode,
		onMultiModeChange,
		onCurrentClassChange,
		createLocation,
		currentClasses=[],
		assignAvailable=false,
    isAdmin=false,
	} = props
	const [choosedLocations, setChoosedLocations] = useState([])
	const [showDeleted, setShowDeleted] = useState(false)
	const [openFilter, setOpenFilter] = useState(false)
	const [keyword, setKeyword] = useState()
	const [showAllCheck, setShowAllCheck] = useState(false)
	const [allCheckedItems, setAllCheckedItems] = useState([])
	const [existLocations, setExistLocations] = useState([])
	const [filteredLocations, setFilteredLocations] = useState([])
	const filterElement = useRef()
	const [renderKey, setRenderKey] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setChoosedLocations([])
	}, [multiMode])

	useEffect(() => {
		if (locations && !choosedLocations.length && locations.length) {
			const firstActiveLocation = locations.find(location =>
				location.drop_date === null && location.isActive
			);
			setChoosedLocations([firstActiveLocation || locations[0]]);
			// setChoosedLocations([locations[0]])
		}
	}, [!multiMode])

	useEffect(() => {
		if( choosedLocations.length>0 ) {
			onLocationChange(choosedLocations)
		}
	}, [choosedLocations])

	useEffect(() => {
		// Only call API if this is a filter change (not the initial mount)
		if (showDeleted && locations) {
			renderLocations(locations);
			setSelectedLocation(locations);
			setRenderKey(renderKey+1);
		}
	}, [showDeleted]);

	useEffect(() => {
		if (locations && locations.length > 0) {
			setLoading(false); // Ensure loading is turned off when prop-based locations arrive
		}
	}, [locations]);

	useEffect(() => {
		if(locations) {
			setSelectedLocation(locations);

			let _filtered_locations = locations.filter(item => {
				if( keyword && item.name.toLowerCase().indexOf(keyword.toLowerCase())==-1 ) {
					return false
				}
				if(showDeleted) {
					return true
				} else {
					return (!item.drop_date && item.isActive) || item.client_location_id === -1
				}
      		})

			if(!showAllCheck && assignAvailable) {
				_filtered_locations = filtered_locations.filter(item => {
					return existLocations.indexOf(parseInt(item.id)) > -1
				})
			}
			setFilteredLocations(_filtered_locations);
		}
	}, [locations, showDeleted, keyword, existLocations, showAllCheck, assignAvailable]);

	function chooseLocation(location) {
		if( multiMode ) {
			let _choosedLocations = choosedLocations.map(i => {return i})
			if( choosedLocations.indexOf(location)>-1 ) {
				_choosedLocations.splice(choosedLocations.indexOf(location), 1)
			} else {
				_choosedLocations.push(location)
			}
			setChoosedLocations(_choosedLocations)
		} else {
			setChoosedLocations([location])
		}
	}

	function setSelectedLocation(location) {
		const firstActiveLocation = locations.find(location =>
			location.drop_date === null && location.isActive
		);
		setChoosedLocations([firstActiveLocation || locations[0]]);
	}
	function switchDropped() {
		setShowDeleted(!showDeleted)
	}

	function updateKeyword(e) {
		setKeyword(e.target.value)
	}

	function swtichAllCheck() {
		setShowAllCheck(!showAllCheck)
		if( showAllCheck==true ) {
			setAllCheckedItems([])
		}
	}

	async function changeClassLocationLink(location) {
		try {
			const copyCurrentClass = {...currentClasses[0]}
			if( existLocations.indexOf(location.client_location_id)==-1 ) {
				const _link = await LocationService.linkLocationClass(currentClasses[0].client_id, location.client_location_id, currentClasses[0].id)
				copyCurrentClass.location_links.push(_link)
			} else {
				const _link = await LocationService.unlinkLocationClass(currentClasses[0].client_id, location.client_location_id, currentClasses[0].id)
				let origin_location_links = currentClasses[0].location_links.filter(item => {
					return item.locationclassid!=_link.locationclassid
				})
				copyCurrentClass.location_links = origin_location_links
			}
			if( onCurrentClassChange ) {
				onCurrentClassChange(copyCurrentClass)
			}
		} catch(e) {
		}
	}

	async function addLocation() {
		let name = 'New Location'
		createLocation({location_name: name, client_location_id: -1, location_code: '', class_links: []})
	}

	return (
    <Box sx={jss.dataGridItem}>
      <Box sx={jss.dataTop}>
        <Box sx={jss.dataTitle}>
          Locations
          {onMultiModeChange && (
            <FormControlLabel
              control={
                <Switch
                  value={multiMode}
                  size="small"
                  onChange={onMultiModeChange}
                />
              }
              label="Multi select"
              sx={{ ml: 2 }}
            />
          )}
        </Box>
      </Box>
      <Box sx={jss.actionSection}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search"
          value={keyword}
          onChange={updateKeyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box sx={jss.dataAction}>
          <IconButton
            size="small"
            onClick={(e) => setOpenFilter(true)}
            ref={filterElement}
          >
            <FilterListRoundedIcon />
          </IconButton>
          {!multiMode && assignAvailable && (
            <IconButton
              size="small"
              sx={showAllCheck ? { ...jss.iconButtonActive } : {}}
              onClick={swtichAllCheck}
            >
              <PlaylistAddCheckIcon />
            </IconButton>
          )}
          <Menu
            open={openFilter}
            anchorEl={filterElement.current}
            onClose={(e) => {
              setOpenFilter(false);
            }}
          >
            <MenuItem
              sx={{ width: 200 }}
              dense
              onClick={(e) => switchDropped()}
            >
              <ListItemIcon>{showDeleted && <Check />}</ListItemIcon>
              <ListItemText>Dropped</ListItemText>
            </MenuItem>
          </Menu>
          {isAdmin ?
            <IconButton size="small" onClick={(e) => addLocation()}>
              <AddRoundedIcon />
            </IconButton>
          : null}
        </Box>
      </Box>
      {(loading || !locations) && (
        <Box sx={jss.loadingSection}>
          <CircularProgress />
        </Box>
      )}
      {locations && (!assignAvailable || currentClasses.length > 0) && (
        <Box sx={jss.dataContent} key={`location-list-${renderKey}`}>
          {filteredLocations.map((_location) => {
            return (
              <Box
                sx={jss.dataContentItem}
                className={
                  choosedLocations
                    .map((i) => {
                      return i.client_location_id;
                    })
                    .indexOf(_location.client_location_id) > -1
                    ? "_active"
                    : _location.drop_date || !_location.isActive
                    ? "_deleted"
                    : ""
                }
                key={`location_item_${_location.client_location_id}`}
              >
                {showAllCheck && (
                  <Checkbox
                    size="small"
                    checked={
                      existLocations.indexOf(_location.client_location_id) > -1
                    }
                    onClick={(e) => changeClassLocationLink(_location)}
                  />
                )}
                <Box
                  sx={jss.dataContentItem.name}
                  onClick={(e) => chooseLocation(_location)}
                >
                  {_location.location_name}
                </Box>
                <Box
                  sx={jss.dataContentItem.action}
                  onClick={(e) => chooseLocation(_location)}
                >
                  {_location.location_code}
                </Box>
              </Box>
            );
          })}
          {assignAvailable && filteredLocations.length == 0 && (
            <Box sx={jss.dataContent.empty}>
              <Box>There are no locations for the selected class.</Box>
              <Box>You can select locations for this class.</Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
export default ClientClassLocationList
