import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	Checkbox,
	Button,
	Tooltip,
	CircularProgress,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SectionTitle from '../../components/common/SectionTitle'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import CurrentClientContext from '../../providers/CurrentClientProvider'
import ClientService from '../../services/ClientService'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PlaylistAddCheckCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import moment from 'moment'
import TipsContext from '../../providers/GlobalTip'
import EmptyIcon from '../../components/icons/EmptyIcon'
import AuthProvider from "../../providers/AuthProvider";
	
const jss = {
	changeLogItem: {
		display:'flex',
		p:1,
		border:'1px solid #ccc',
		borderRadius:1,
		mt:1,
		transition: 'all 0.3s',
		info: {
			flex:1,
			wordBreak:'break-all',
			cursor:'pointer',
		},
		name: {
			whiteSpace:"wrap",
			fontSize:13,
			fontWeight:'bold',
		},
		extra: {
			fontSize:12,
			mt:0.5,
		},
		checkIcon: {
			ml:1,
			cursor:'pointer',
		},
		":hover": {
			backgroundColor: '#f3f4f5',
		},
		'&.checked': {
			backgroundColor: '#dbcfe6',
			color:'primary.main',
			borderColor: 'primary.main'
		},
	},
	emptySelectLog: {
		textAlign:'center', 
		py:15, 
		color:'text.disabled', 
		fontSize:30
	},
	changeLogSection: {
		title: {
			fontSize:16,
			fontWeight:'bold',
			my:2
		},
		actionSection: {
			p:2,
			backgroundColor: '#eee9f3',
			display:'flex',
			alignItems:'center',
		},
	},
}

function ImportClass(props) {
	const fileInputRef = useRef()
	const [changeLogs, setChangeLogs] = useState()
	const [loading, setLoading] = useState(true)
	const [uploading, setUploading] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [loadingSelect, setLoadingSelect] = useState(false)
	const currentClientContext = CurrentClientContext.useClient()
	const [choosedChangeLog, setChoosedChangeLog] = useState()
	const [targetChangeLog, setTargetChangeLog] = useState()
	const [changeLogSummary, setChangeLogSummary] = useState([])
	const tip_context = TipsContext.useMessage()
	const auth = AuthProvider.useAuth()
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {
		document.title = 'Retail ORBIT: Client Class Import'
	}, [])

	useEffect(() => {
		if( auth.checkPermission('role-create') ) {
			setIsAdmin(true)
		}
	}, [auth.user])

	useEffect(() => {
		if( currentClientContext.client ) {
			loadChangeLogs()	
		}
	}, [currentClientContext.client])

	useEffect(() => {
		if( targetChangeLog ) {
			calculateChangeLogSummary()
		}
	}, [targetChangeLog])

	async function loadChangeLogs() {
		const _changeLog = await ClientService.getClientChangeLogs(currentClientContext.client.Id)
		setLoading(false)
		setChangeLogs(_changeLog)
	}

	async function selectChangeLog(log) {
		setChoosedChangeLog(log)
		setLoadingSelect(true)
		const _log = await ClientService.getClientChangeLogById(currentClientContext.client.Id, log.id)
		setTargetChangeLog(_log)
		setLoadingSelect(false)
	}

	async function deleteChangeLog(log) {
		await ClientService.deleteClientChangeLogById(currentClientContext.client.Id, log.id)
		const _changeLogs = []
		changeLogs.forEach(item => {
			if( item.id != log.id ) {
				_changeLogs.push(item)
			}
		})
		setChangeLogs(_changeLogs)
	}

	async function downloadTargetChangeLog() {
		ClientService.downloadClientChangeLog(currentClientContext.client.Id, targetChangeLog.id)
	}

	function calculateChangeLogSummary() {
		let _origin_summary = {}
		let _summary = []
		_origin_summary['_total'] = {
			Code: '',
			Name: "Total",
			Classes: 0,
			OTB: 0,
			PNOTB: 0,
			NOTB: 0,
			Other: 0,
		}
		targetChangeLog.actions.forEach(_action => {
			if( _action.class_code ) {
				if( !_origin_summary.hasOwnProperty(_action.location_code) ) {
					_origin_summary[ _action.location_code ] = {
						Code: _action.location_code,
						Name: _action.location_name,
						Classes: 0,
						OTB: 0,
						PNOTB: 0,
						NOTB: 0,
						Other: 0,
					}
				}
				if( _action.service_type=='Child' ) {
					_origin_summary[ _action.location_code ][ 'Other' ]++;
					_origin_summary[ '_total' ][ 'Other' ]++;
				} else {
					_origin_summary[ _action.location_code ][ _action.service_type ]++;
					_origin_summary[ '_total' ][ _action.service_type ]++;	
				}
				_origin_summary[ _action.location_code ][ 'Classes' ]++;
				_origin_summary[ '_total' ][ 'Classes' ]++;
			}
		})
		_summary.push(_origin_summary._total)
		for(let i in _origin_summary) {
			if( i!='_total' ) {
				_summary.push(_origin_summary[i])
			}
		}
		setChangeLogSummary(_summary)
	}

	function chooseFile() {
		fileInputRef.current.click()
	}

	async function uploadChangeLog(e) {
		try {
			setUploading(true)
			const new_change_log = await ClientService.uploadClassData(currentClientContext.client.Id, e.target.files[0])
			setChangeLogs([new_change_log].concat(changeLogs))	
		} catch(e) {
			tip_context.show(e.response?.data.message)
		}
		setUploading(false)
	}

	async function startProcessing() {
		try {
			setProcessing(true)
			const _change_log = await ClientService.processClientChangeLog(currentClientContext.client.Id, targetChangeLog.id)
			setTargetChangeLog(_change_log)
		} catch(e) {
			tip_context.show(e.response?.data.message)
		}
		setProcessing(false)
	}

	return <Box sx={{py:2}}>
		<Typography variant="h5">Client Import</Typography>
		<Grid container spacing={2} sx={{mt:0}}>
			<Grid item xs={12} sm={3}>
				<Paper elevation={2}>
					<SectionTitle 
						label="Import Classes"
						rightComponent={
							<Tooltip title="Download Setup Package">
								<IconButton component="a" href="/templates/client_setup_package.zip" target="_blank">
									<DownloadRoundedIcon />
								</IconButton>
							</Tooltip>
						}
					/>
					<Box sx={{p:1.5}}>
						<Box 
							component="input" 
							type='file' 
							name="data" 
							sx={{display:'none'}} 
							ref={fileInputRef}
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
							onChange={uploadChangeLog}
						></Box>
						{isAdmin ?
							<LoadingButton loading={uploading} variant="contained" fullWidth startIcon={<CloudUploadRoundedIcon />} onClick={chooseFile}>Upload File</LoadingButton>
						: null}
						<Typography variant="body2" sx={{mt:3, mb:1}}>Class Change Files</Typography>
						{changeLogs?.length>0 && changeLogs.map(_log => {
							return <Box key={`change_log_${_log.id}`} sx={jss.changeLogItem} className={choosedChangeLog?.id==_log.id ? 'checked' : ''} >
								<Box sx={jss.changeLogItem.info} onClick={e => selectChangeLog(_log)}>
									<Box sx={jss.changeLogItem.name}>{_log.name}</Box>
									<Box sx={jss.changeLogItem.extra}>Added: {_log.actions_count}</Box>
								</Box>
								{_log.processed_date && 
									<CheckRoundedIcon sx={jss.changeLogItem.checkIcon}/>
								}
								{!_log.processed_date && 
									<ClearOutlinedIcon sx={jss.changeLogItem.checkIcon} onClick={e => deleteChangeLog(_log)}/>
								}
							</Box>
						})}
						{loading && <Box sx={{textAlign:'center',pt:2}}>
							<CircularProgress color="primary" />
						</Box>
						}
						{changeLogs?.length===0 && !loading && 
							<Box sx={{py:2,textAlign:'center',color:'text.disabled'}}><EmptyIcon sx={{fontSize:60}}/></Box>
						}
					</Box>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={9}>
				<Paper elevation={2}>
					{(!targetChangeLog && !loadingSelect) && 
						<Box sx={jss.emptySelectLog}>
							Please select a class change file
						</Box>
					}
					{loadingSelect && 
						<Box sx={jss.emptySelectLog}>
							<CircularProgress color="primary" />
						</Box>
					}
					{targetChangeLog && 
						<Box sx={jss.changeLogSection}>
							<SectionTitle 
								label='Class Change'
								subLabel={targetChangeLog.processed_date ? "File Processed Successfully" : ""}
								rightComponent={
									<IconButton onClick={downloadTargetChangeLog}>
										<DownloadRoundedIcon />
									</IconButton>
								}
							/>
							<Box sx={{p:2}}>
								<Box sx={jss.changeLogSection.title}>Change Summary</Box>
								<TableContainer>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Code</TableCell>
												<TableCell>Name</TableCell>
												<TableCell>Classes</TableCell>
												<TableCell>OTB</TableCell>
												<TableCell>PNOTB</TableCell>
												<TableCell>NOTB</TableCell>
												<TableCell>Other</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{changeLogSummary.map((item, ) => {
												return <TableRow key={`summary_item_${item.Name}`}>
													<TableCell><b>{item.Code}</b></TableCell>
													<TableCell><b>{item.Name}</b></TableCell>
													<TableCell>{item.Classes}</TableCell>
													<TableCell>{item.OTB}</TableCell>
													<TableCell>{item.PNOTB}</TableCell>
													<TableCell>{item.NOTB}</TableCell>
													<TableCell>{item.Other}</TableCell>
												</TableRow>	
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
							<Box sx={jss.changeLogSection.actionSection}>
								<LoadingButton variant="contained" loading={processing} onClick={startProcessing} startIcon={<PlaylistAddCheckCircleOutlinedIcon />}>Process Changes</LoadingButton>
								{targetChangeLog.processed_date &&
									<Box sx={{ml:2}}><b>File Processed Successfully:</b> {moment(targetChangeLog.processed_date).format('MM/DD/YYYY')}</Box>
								}
							</Box>
							<Box sx={{p:2}}>
								<TableContainer>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Action</TableCell>
												<TableCell>Location Code</TableCell>
												<TableCell>Location Name</TableCell>
												<TableCell>Class Code</TableCell>
												<TableCell>Class Name</TableCell>
												<TableCell>Parent Code</TableCell>
												<TableCell>Parent Location</TableCell>
												<TableCell>Service</TableCell>
												<TableCell>Category</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
										{targetChangeLog.actions.map(_action => {
											return <TableRow key={`action_item_${_action.id}`}>
												<TableCell>{_action.action}</TableCell>
												<TableCell>{_action.location_code}</TableCell>
												<TableCell>{_action.location_name}</TableCell>
												<TableCell>{_action.class_code}</TableCell>
												<TableCell>{_action.class_name}</TableCell>
												<TableCell>{_action.parent_class_code}</TableCell>
												<TableCell>{_action.parent_location_code}</TableCell>
												<TableCell>{_action.service_type}</TableCell>
												<TableCell>{_action.class_category}</TableCell>
											</TableRow>
										})}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Box>
					}
				</Paper>
			</Grid>
		</Grid>
	</Box>
}
export default ImportClass