import React, {useState, useRef, useEffect} from 'react';
import ModifyStyle from '../../components/plan/PlanStyle';
import ClientService from '../../services/ClientService';
import PlanService from '../../services/PlanService';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import MessageContext from '../../providers/GlobalTip';
import {
    TextField,
    Grid,
    Button,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, 
    Chip, 
    Paper,
    Backdrop,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import {
	LoadingButton
} from '@mui/lab'
import { 
	FuturePlanAnnualForm, 
	FuturePlanTableData, 
	colorMap, 
	FuturePlanTableDataMap
} from '../../models/FuturePlanModel';
import cloneDeep from 'lodash.clonedeep';
import _ from 'lodash';
import numeral from 'numeral';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import moment from 'moment'
import PlanCalculatorService from '../../services/PlanCalculatorService'
import BigTable from '../../components/plan/BigTable';
import PlanCopyToModal from './PlanCopyToModal';
import useConfirm from '../../providers/ConfirmDialog';
import { handleTrackError } from '../../services/AmplitudeService';

function FuturePlanEdit(props) {

	const {
		clientBasicData,
		optionValue,
		onOptionValueChange,
		client
	} = props
	const [loading, setLoading] = useState(true);
	const [loadError, setLoadError] = useState()
  const [planData, setPlanData] = useState()
  const [annualForm, setAnnualForm] = useState({})
  const [displayMonths, setDisplayMonths] = useState([])
  const [tableData, setTableData] = useState(new Map())
  const currentUpdateRef = useRef(null);
  const [singleSaving, setSingleSaving] = useState(false)
  const [blukSaving, setBlukSaving] = useState(false)
  const messageContext = MessageContext.useMessage()
  const [costOfPurchase, setCostOfPurchase] = useState(0)
  const dataLoaded = useRef(false)
  const [actions, setActions] = useState({
      month: null,
      year: null,
      type: null,
  })
  const [tabs, setTabs] = useState([
      {
          label: 'Target', 
          titles: ['Target', 'Plan'],
          values: [
              ['Cash', 0],
              ['GMROI', 0],
              ['CMROI', 0],
              ['MMU', 0],
          ],
      },
  ])
  const [tab, setTab] = useState(tabs[0]?.label)
  const [buildingAll, setBuildingAll] = useState(false)
  const [buildingAllLocation, setBuildingAllLocation] = useState(false)
  const [displayCopyToModal, setDisplayCopyToModal] = useState(false);
  const confirm = useConfirm();
  const currentClient = CurrentClientContext.useClient();

    useEffect(() => {
    	loadLocationClassFuturePlanData()
    }, [clientBasicData])

    useEffect(() => {
        if( planData ) {
            initPlanData()
        }
    }, [planData])

    useEffect(() => {
        if( !_.isEmpty(tableData) && !_.isEmpty(annualForm) ) {
            updateTargetTableData();
            updateCostOfPurchase();
        }
    }, [tableData, annualForm])

    const initPlanData = () => {
    	const _annualForm = cloneDeep(FuturePlanAnnualForm)
        initAnnualForm(_annualForm)
        setAnnualForm(_annualForm)
        const _display_months = planData.display_months.map(item => {
            const [month, year] = item.monthDisplay.split(' ');
            return ({...item, month, year})
        });
        const basePlanTableData = cloneDeep(FuturePlanTableDataMap);
        for(const [key, data] of basePlanTableData) {
          if(data instanceof Map) {
            for(const [_, val] of data) {
                val.innerData = planData[val.sourceKey].map(item => {
                    const value = +(item[val.key] ?? 0);
                    return {
                        value: value,
                        orig: value,
                        current: value
                    }
                })
            }
          }
        }
        setTableData(basePlanTableData)
        setDisplayMonths(_display_months)
        if( planData.base?.action_type ) {
            setActions({
                month: planData.base.action_month,
                year: planData.base.action_year,
                type: planData.base.action_type,
            })    
        }
    }

    const loadLocationClassFuturePlanData = async() => {
    	try {
    		setLoading(true)
	        const data = await PlanService.getLocationClassFuturePlanDetail(client.Id, clientBasicData.current_location_class.id)
	        setPlanData(data)
    	} catch(e) {
    		setLoadError(e.response?.data?.message)

            handleTrackError({ error: e, pageName: 'Future Plan', overrideMessage: 'Failed to load future plan', additionalMetadata: { clientCode: currentClient?.client?.ClientCode } })
    	}
        setLoading(false)
    }

    const loadLocationClassPlanLiveData = async(location_class_id = null) => {
    	try {
    		setLoading(true)
	        let data = await PlanService.getLocationClassLivePlanDetail(client.Id, location_class_id ?? clientBasicData.current_location_class.id)
            data.base = {...data.base, ...data.base.setting}
	        setPlanData(data)
    	} catch(e) {
    		setLoadError(e.response?.data?.message)

            handleTrackError({ error: e, pageName: 'Future Plan', overrideMessage: 'Failed to load future plan', additionalMetadata: { clientCode: currentClient?.client?.ClientCode } })
    	}
        setLoading(false)
    }

    const initAnnualForm = (form = {}) => {
        for(const key in form) {
            if(planData?.base.hasOwnProperty(key)) {
                const value = +planData.base[key];
                form[key].value = value;
                form[key].current = value;
                form[key].orig = value;
                if(form[key].chipProps) {
                    initAnnualForm(form[key].chipProps)
                }
            }
        }
    }

    const onTabChange = (value) => {
        value !== tab && setTab(value)
    }

    const removeCommas = (str) => {
        while (str?.search(",") >= 0) {
            str = (str + "").replace(',', '');
        }
        return str || '0';
    }

    const numberWithCommas = (num) => {
        if(!isNaN(num)){
            num = parseInt(num);
         return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    }

    const updateAnnualForm = (key, updateParams) => {
        const item = annualForm[key];
        setAnnualForm({
            ...annualForm,
            [key]: {...item, ...updateParams}
        })
    }

    const changeAnnualSales = (params, form) => {
        const smt = parseFloat(params.smt);
        let annualSalesAmt = 0;
        switch(smt) {
            case 0:
                annualSalesAmt = params.value;
                break;
            case 1:
            case 2:
            case 3:
            case 4:
                annualSalesAmt = params.current;
                break;
        };
        const _annualForm = form ?? cloneDeep(annualForm);
        const _tableData = cloneDeep(tableData);
        const previousAnnualSalesAmt = _annualForm.SalesAnnualPlan.current;
        if(annualSalesAmt != previousAnnualSalesAmt) {
            _annualForm.SalesAnnualPlan.value = annualSalesAmt;
            _annualForm.SalesAnnualPlan.current = annualSalesAmt;
            const annualMarkdownAmt = _annualForm.MarkdownsAnnualPercentPlan.value;
            const md_per = annualMarkdownAmt / 100;
            const an_sales = annualSalesAmt;
            const md_amt = an_sales * md_per;
            const markdownsPercentPlan = _tableData.get('Markdowns').get('MarkdownsPercentPlan');
            const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
            const stockToSalesPlan = _tableData.get('Inventory').get('StockToSalesPlan');
            const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
            // updateMarkdownsPlan
            markdownsPlan.innerData = markdownsPlan.innerData.map((val, index) => {
                const value = +(md_amt * (markdownsPercentPlan.innerData[index].value / 100)).toFixed(0)
                return {...val, value}
            })
            // updateSalesPlan
            const salesPercentPlan = _tableData.get('Sales').get('SalesPercentPlan');
            const salesPlan = _tableData.get('Sales').get('SalesPlan');
            salesPlan.innerData = salesPlan.innerData.map((val, index) => {
                const value = +(an_sales * salesPercentPlan.innerData[index].value / 100).toFixed(0)
                return {...val, value}
            })
            // updateInventoryBomPlan
            inventoryBomPlan.innerData = inventoryBomPlan.innerData.map((val, index) => {
                const sales = salesPlan.innerData[index].value;
                const ss =  stockToSalesPlan.innerData[index].value;
                const inv = +(sales * ss).toFixed(0)
                return {...val, value: inv, current: inv}
            })
            PlanCalculatorService.update13thMonth(_tableData);
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            PlanCalculatorService.balanceInflow(_tableData, _annualForm);
            setTableData(_tableData);
            setAnnualForm(_annualForm)
        }
    }

    const changeAnnualMarkdowns = (params) => {
        const annualMarkdownAmt = params.value;
        const _annualForm = cloneDeep(annualForm);
        const _tableData = cloneDeep(tableData);
        const previousMarkdownAmt = _annualForm.MarkdownsAnnualPercentPlan.current;
        if(annualMarkdownAmt != previousMarkdownAmt) {
            _annualForm.MarkdownsAnnualPercentPlan.value = annualMarkdownAmt;
            _annualForm.MarkdownsAnnualPercentPlan.current = annualMarkdownAmt;
            const md_per = annualMarkdownAmt / 100;
            const an_sales = _annualForm.SalesAnnualPlan.value;
            const md_amt = an_sales * md_per;
            const markdownsPercentPlan = _tableData.get('Markdowns').get('MarkdownsPercentPlan');
            const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
            markdownsPlan.innerData = markdownsPlan.innerData.map((val, index) => {
                const md_per_plan = markdownsPercentPlan.innerData[index].value;
                const new_md_plan = +(md_amt * (md_per_plan / 100)).toFixed(0);
                return {...val, value: new_md_plan}
            });
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm)
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            setTableData(_tableData)
            setAnnualForm(_annualForm)
        }
    }

    const changeAnnualTurn = (params) => {
        const _annualForm = cloneDeep(annualForm);
        const _tableData = cloneDeep(tableData);
        const turnAmt = params.value;
        const previousTurn = _annualForm.TurnPlan.current;
        if(turnAmt != previousTurn) {
            _annualForm.TurnPlan.current = turnAmt;
            if(turnAmt == 0 || turnAmt == '') {
                const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
                const stockToSalesPlan = _tableData.get('Inventory').get('StockToSalesPlan');
                inventoryBomPlan.innerData = inventoryBomPlan.innerData.map((val, index) => ({...val, value:0, current: 0}))
                stockToSalesPlan.innerData = stockToSalesPlan.innerData.map((val, index) => ({...val, value: 0, current: 0}))
                PlanCalculatorService.update13thMonth(_tableData);
                PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
                PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
                PlanCalculatorService.updateTurn(_tableData, _annualForm)
                setTableData(_tableData)
            }else if (turnAmt < 0) {
                _annualForm.TurnPlan.value = previousTurn;
            }else {
                _annualForm.TurnPlan.value = turnAmt;
                const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
                const annualSalesAmt = _annualForm.SalesAnnualPlan.value;
                const avgInv = annualSalesAmt / turnAmt;
                let origAvgInv = 0;
                if(previousTurn > 0){
                    origAvgInv = annualSalesAmt / previousTurn;
                } 
                let turnAdd = false;
                if(previousTurn > turnAmt || previousTurn == 0){
                    turnAdd = true;
                }
                let avgMonInvChange = origAvgInv - avgInv;
                let nonZeroCnt = 0;
                let zeroInv = 0;
                do {
                    nonZeroCnt = 0;
                    if(zeroInv > 0) {
                        inventoryBomPlan.innerData.forEach((val, index) => {
                            if(val.value > 0) {
                                nonZeroCnt = nonZeroCnt + 1;
                            }
                        })
                        avgMonInvChange = zeroInv / nonZeroCnt;
                        zeroInv = 0
                    }
                    const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
                    inventoryBomPlanInnerData.forEach((val, index) => {
                        const origValue = val.value;
                        if(origValue > 0 || previousTurn == 0 && index < 12) {
                            let newVal = 0;
                            if(turnAdd) {
                                newVal = origValue + Math.abs(avgMonInvChange);
                            }else {
                                newVal = origValue - Math.abs(avgMonInvChange);
                                if(newVal < 0) {
                                    zeroInv = zeroInv + Math.abs(newVal);
                                    nonZeroCnt = 1;
                                    newVal = 0
                                }
                            }
                            const value = (newVal).toFixed(0);
                            inventoryBomPlanInnerData[index].value = +value;
                            inventoryBomPlanInnerData[index].current = +value;
                        }
                    })
                    inventoryBomPlan.innerData = inventoryBomPlanInnerData;
                }while(nonZeroCnt > 0 && zeroInv > 0);
                PlanCalculatorService.updateStockToSales(_tableData);
                PlanCalculatorService.update13thMonth(_tableData);
                PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
                PlanCalculatorService.updateTurn(_tableData, _annualForm)
                setTableData(_tableData)
            }
            setAnnualForm(_annualForm);
        }
    }

    const changeAnnualImu = (params) => {
        const _annualForm = cloneDeep(annualForm);
        const imt = parseFloat(params.imt);
        let imuAmt = 0;
        switch(imt){
            case 0:
                imuAmt = params.value;
                break;
            case 1:
            case 2:
                imuAmt = params.current;
                break;
        }
        const previousImu = _annualForm.ImuPlan.current;
        if(imuAmt != previousImu) {
            const _tableData = cloneDeep(tableData);
            _annualForm.ImuPlan.value = imuAmt;
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            setAnnualForm(_annualForm);
            setTableData(_tableData);
        }

    }

    const getKFlow = () => {
        const _tableData = cloneDeep(tableData);
        const _annualForm = cloneDeep(annualForm);
        PlanCalculatorService.getKFlow(_tableData, _annualForm)
        setTableData(_tableData);
        setAnnualForm(_annualForm)
    }

    const onAnnualFormBlur = (key, params) => {
        currentUpdateRef.current = key
        if(key === 'sales_annual_plan') {
            changeAnnualSales(params)
        }else if(key === 'markdowns_annual_percent_plan') {
            changeAnnualMarkdowns(params)
        }else if(key === 'turn_plan') {
            changeAnnualTurn(params)
        }else if(key === 'imu_plan') {
            changeAnnualImu(params)
        }
    }

    const updateTableForm = (e, key, innerKey, colIndex) => {
        const _tableData = cloneDeep(tableData);
        const value = e.target.value.includes('.') ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
        _tableData.get(key).get(innerKey).innerData[colIndex].value = value;
        setTableData(_tableData)
    }

    const loadPlan = (type) => {
    	if( type == 1 ) {
    		loadLocationClassPlanLiveData()
    	} 
    }

    const onBlurTableForm = (tableKey, rowKey, colIndex) => {
        if(rowKey === 'SalesPercentPlan') {
            salesUpdate(tableKey, rowKey, colIndex)
        }else if(rowKey === 'StockToSalesPlan') {
            stocktosalesplanUpdate(tableKey, rowKey, colIndex)
        }else if(rowKey === 'InventoryBomPlan') {
            inventoryUpdate(tableKey, rowKey, colIndex)
        }else if(rowKey === 'MarkdownsPercentPlan') {
            markdownsUpdate(tableKey, rowKey, colIndex)
        }
    }

    const salesUpdate = (tableKey, rowKey, colIndex) => {
        const _tableData = cloneDeep(tableData);
        const _annualForm = cloneDeep(annualForm); 
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const targetRowInnerData = targetRow.innerData;
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const salesPlanInnerData = salesPlan.innerData;
        const { value, current } = targetRowInnerData[colIndex];
        if(value != current) {
            targetRowInnerData[colIndex].current = value;
            const annualSalesAmt = annualForm.SalesAnnualPlan.value;
            salesPlanInnerData[colIndex].value = +(annualSalesAmt * value / 100).toFixed(0);
            salesPlan.innerData = salesPlanInnerData;
            PlanCalculatorService.updateStockToSales(_tableData);
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            setTableData(_tableData);
            setAnnualForm(_annualForm);
        }
    }

    const isInt = (value) => {
        var x;
        if (isNaN(value)) {
            return false;
        }
        x = parseFloat(value);
        return (x | 0) === x;
    }

    const markdownsUpdate = (tableKey, rowKey, colIndex) => {
        const _tableData = cloneDeep(tableData);
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const targetRowInnerData = targetRow.innerData;
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const markdownsPlanInnerData = markdownsPlan.innerData;
        const { value, current } = targetRowInnerData[colIndex];
        if(value != current) {
            targetRowInnerData[colIndex].current = value;
            const annualSalesAmt = annualForm.SalesAnnualPlan.value;
            const annualMarkdownPer = annualForm.MarkdownsAnnualPercentPlan.value / 100;
            const annualMarkdownAmt = +(annualSalesAmt * annualMarkdownPer).toFixed(0);
            const markdownPlanAmt = +(annualMarkdownAmt * (value / 100)).toFixed(0);
            markdownsPlanInnerData[colIndex].value = markdownPlanAmt;
            setTableData(_tableData);
        }
    }

    const stocktosalesplanUpdate = (tableKey, rowKey, colIndex) => {
        const _tableData = cloneDeep(tableData);
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const targetRowInnerData = targetRow.innerData;
        const inventoryBomPlan = _tableData.get('Inventory').get('InventoryBomPlan');
        const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const salesPlanInnerData = salesPlan.innerData;
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const markdownsPlanInnerData = markdownsPlan.innerData;
        const _annualForm = cloneDeep(annualForm)

        const { value, current } = targetRowInnerData[colIndex];
        if(value != current) {
            targetRowInnerData[colIndex].current = value;

            if(colIndex > 0 && colIndex < 12) {
                const prevIndex = colIndex - 1;
                const p_inv = inventoryBomPlanInnerData[prevIndex].value;
                const p_sales = salesPlanInnerData[prevIndex].value;
                const p_markdowns = markdownsPlanInnerData[prevIndex].value;
                const p_remainder = p_inv - p_sales - p_markdowns;
                const sales = salesPlanInnerData[colIndex].value;
                const inv = sales * value;
                if(p_remainder >  inv) {
                    inventoryBomPlanInnerData[colIndex].value = +(p_remainder).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(p_remainder).toFixed(0);
                }else {
                    inventoryBomPlanInnerData[colIndex].value = +(inv).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(inv).toFixed(0);
                }
            }else if(colIndex == 12) {
                const prevIndex = colIndex - 1;
                const p_inv = inventoryBomPlanInnerData[prevIndex].value;
                const p_sales = salesPlanInnerData[prevIndex].value;
                const p_markdowns = markdownsPlanInnerData[prevIndex].value;
                const p_remainder = p_inv - p_sales - p_markdowns;
                const sales = salesPlanInnerData[0].value;
                const inv = sales * value;
                if(p_remainder >  inv) {
                    inventoryBomPlanInnerData[colIndex].value = +(p_remainder).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(p_remainder).toFixed(0);
                }else {
                    inventoryBomPlanInnerData[colIndex].value = +(inv).toFixed(0);
                    inventoryBomPlanInnerData[colIndex].current = +(inv).toFixed(0);
                }
            }else if(colIndex == 0 && value > 0) {
                const sales = salesPlanInnerData[0].value;
                const inv = +(sales * value).toFixed(0);
                inventoryBomPlanInnerData[colIndex].value = inv;
                inventoryBomPlanInnerData[colIndex].current = inv;

            }else if(colIndex == 0 && value <= 0) {
                inventoryBomPlanInnerData[colIndex].value = 0;
                inventoryBomPlanInnerData[colIndex].current = 0;
            }
            targetRow.innerData = targetRowInnerData;
            inventoryBomPlan.innerData = inventoryBomPlanInnerData;
            PlanCalculatorService.updateStockToSales(_tableData);
            PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
            PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
            PlanCalculatorService.updateTurn(_tableData, _annualForm);
            setTableData(_tableData)
            setAnnualForm(_annualForm)
        }
    }

    const inventoryUpdate = (tableKey, rowKey, colIndex) => {
        const _tableData = cloneDeep(tableData);
        const targetRow = _tableData.get(tableKey).get(rowKey);
        const salesPlan = _tableData.get('Sales').get('SalesPlan');
        const markdownsPlan = _tableData.get('Markdowns').get('MarkdownsPlan');
        const innerData = targetRow.innerData;
        const { value, current } = innerData[colIndex];
        const _annualForm = cloneDeep(annualForm)
        if(current != value) {
            innerData[colIndex].current = value;
            if(colIndex > 0) {
                const prevIndex = colIndex - 1;
                const p_inv = innerData[prevIndex].value;
                const p_sales = salesPlan.innerData[prevIndex].value;
                const p_markdowns = markdownsPlan.innerData[prevIndex].value;
                const p_remainder = p_inv - p_sales - p_markdowns;
                if(p_remainder > value) {
                    innerData[colIndex].value = p_remainder;
                }
            };
            targetRow.innerData = innerData;
        }
        PlanCalculatorService.updateStockToSales(_tableData)
        PlanCalculatorService.updateInflowRetail(_tableData, _annualForm)
        PlanCalculatorService.updateInflowCost(_tableData, _annualForm)
        PlanCalculatorService.updateTurn(_tableData, _annualForm)
        setTableData(_tableData)
        setAnnualForm(_annualForm)
    }

    const getSubmitData = () => {
        const requestData = PlanCalculatorService.getSubmitData(planData, annualForm, tableData, actions)
        return requestData;
    }

    const updateCostOfPurchase = () => {
        const _annualForm = annualForm
        let annual_sales = parseInt(removeCommas(`${_annualForm.SalesAnnualPlan.value}`));
        let total_inflow_cost_plan = parseInt(removeCommas(`${tableData.get('Inventory').get("InflowPlanCost").summary}`));
        let cost_of_purchase = 0;
        if(annual_sales > 0){
            cost_of_purchase = ((total_inflow_cost_plan / annual_sales) * 100).toFixed(2);
        }
        setCostOfPurchase(cost_of_purchase)
    }

    const getBaseLog = (x, y) => {
		return Math.log(x) / Math.log(y);
	}

    const updatePlan = async() => {
    	try {
    		setSingleSaving(true)
    		await PlanService.saveLocationClassFuturePlan( client.Id, clientBasicData.current_location_class.id, getSubmitData() )
    		messageContext.showSuccess('Plan save successfully')
    	} catch(e) {
    		messageContext.show(e.response?.data?.message)

            handleTrackError({ error: e, pageName: 'Future Plan', overrideMessage: 'Failed to save future plan', additionalMetadata: { clientCode: currentClient?.client?.ClientCode } })
    	}
    	setSingleSaving(false)
    }

    const reloadPlan = () => {
    	initPlanData()
    }

    const updateTargetTableData = () => {
        const _tableData = tableData;
        let total_sales = parseInt(_tableData.get('Sales').get('SalesPlan').summary);
        let total_inflow_cost = parseInt(_tableData.get('Inventory').get('InflowPlanCost').summary);
        let total_inflow_retail = parseInt(_tableData.get('Inventory').get('InflowPlanRetail').summary);
        let total_markdowns = parseInt(_tableData.get('Markdowns').get('MarkdownsPlan').summary);

        // updateTargetCash
        let target_cash = 0;
        if(total_sales > 0){
            target_cash = (((total_sales - total_inflow_cost) / total_sales) * 100).toFixed(2);
        }

        // updateTargetGmroi
        let imu = parseFloat(annualForm.ImuPlan.value);
        let avg_inv = _tableData.get('Inventory').get('InventoryBomPlan').avg;
        let avg_inv_cost = avg_inv * (1 - (imu / 100));
        let _mmu = ((total_inflow_retail - total_inflow_cost) / total_inflow_retail - ((total_markdowns / total_sales) * (1 - (total_inflow_retail - total_inflow_cost) / total_inflow_retail)));
        let mmu = (_mmu).toFixed(2);
        let gmroi = ((mmu * total_sales) / avg_inv_cost).toFixed(2);
        if(!_.isFinite(parseFloat(gmroi))){
            gmroi = 0;
        }

        // updateTargetCmroi
        let cmroi = ((total_sales - total_inflow_cost) / avg_inv_cost).toFixed(2);
        if(!_.isFinite(parseFloat(cmroi))){
            cmroi = 0;
        }

        // updateTargetMmu
        if(isNaN(_mmu)){
            _mmu = 0;
        }
        const _tabs = cloneDeep(tabs)
        _tabs[0].values[0][1] = target_cash
        _tabs[0].values[1][1] = gmroi
        _tabs[0].values[2][1] = cmroi
        _tabs[0].values[3][1] = (_mmu * 100).toFixed(2)
        setTabs(_tabs)
    }

    const onBuildAll = async(type) => {
        const choice = await confirm({ 
            description: "Are you sure to build?"
        });
        if (choice) { 
            try {
                if( type == 'all_location' ) {
                    setBuildingAllLocation(true)
                } else if( type == 'all_client' ) {
                    setBuildingAll(true)
                }
                await PlanService.bulkSaveLocationClassFuturePlan(client.Id, clientBasicData.current_location_class.locationclassid, type, getSubmitData())
                messageContext.showSuccess('Build successfully')
            } catch(e) {
                messageContext.show(e.response?.data?.message)

                handleTrackError({ error: e, pageName: 'Future Plan', overrideMessage: 'Failed to build future plan', additionalMetadata: { clientCode: currentClient?.client?.ClientCode } })
            }
            setBuildingAllLocation(false)
            setBuildingAll(false)
        }
    }

    if( loading ) {
    	return <Box sx={ModifyStyle.loadingSection}>
            <CircularProgress color="primary" size={50} />
        </Box>
    }

    if( !loading && loadError ) {
    	return <Box sx={ModifyStyle.loadingSection}>
    		<Box><GppMaybeOutlinedIcon sx={{ fontSize: 50 }} /></Box>
            <Box>{loadError}</Box>
        </Box>
    }

    const showCopyToModal = () => {
        setDisplayCopyToModal(true)
    }

	return <>
        <Box sx={ModifyStyle.leftSection}>
            <Box sx={ModifyStyle.leftContent}>
                <Box sx={ModifyStyle.loadPlan}>
                    <Box sx={ModifyStyle.loadPlanTitle}>Build</Box>
                    <Box sx={{mt: 1}}>
                        <RadioGroup 
                            row 
                            value={actions.type} 
                            onChange={e => setActions({
                                ...actions,
                                type: e.target.value
                            })}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Opening" />
                            <FormControlLabel value="2" control={<Radio />} label="Closing" />
                        </RadioGroup>
                        <Box sx={{mt: 1}}>
                            <FormControl size="small">
                                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                <Select
                                    value={actions.month}
                                    size="small"
                                    label="Month"
                                    sx={{
                                        minWidth: 100,
                                        mr: 1,
                                    }}
                                    onChange={e => setActions({
                                        ...actions,
                                        month: e.target.value
                                    })}
                                >
                                    <MenuItem value={1}>Jan</MenuItem>
                                    <MenuItem value={2}>Feb</MenuItem>
                                    <MenuItem value={3}>Mar</MenuItem>
                                    <MenuItem value={4}>Apr</MenuItem>
                                    <MenuItem value={5}>May</MenuItem>
                                    <MenuItem value={6}>Jun</MenuItem>
                                    <MenuItem value={7}>Jul</MenuItem>
                                    <MenuItem value={8}>Aug</MenuItem>
                                    <MenuItem value={9}>Sep</MenuItem>
                                    <MenuItem value={10}>Oct</MenuItem>
                                    <MenuItem value={11}>Nov</MenuItem>
                                    <MenuItem value={12}>Dec</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small">
                                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                <Select
                                    value={actions.year}
                                    size="small"
                                    label="Year"
                                    sx={{
                                        minWidth: 100
                                    }}
                                    onChange={e => setActions({
                                        ...actions,
                                        year: e.target.value
                                    })}
                                >
                                {(new Array(3)).fill(null).map((v, i) => {
                                    const _year = moment().add(i, 'year').format('YYYY')
                                    return <MenuItem value={_year} key={`action_year_item_${i}`}>{_year}</MenuItem>
                                })}
                                </Select>
                            </FormControl>
                        </Box>
                        <LoadingButton loading={singleSaving} onClick={updatePlan} variant="contained" sx={{mt: 1}} fullWidth>UPDATE PLAN</LoadingButton>
                        <LoadingButton loading={buildingAll} onClick={() => onBuildAll('all_client')} variant="outlined" sx={{mt: 1}} fullWidth>Build All Client</LoadingButton>
                        <LoadingButton loading={buildingAllLocation} onClick={() => onBuildAll('all_location')} variant="outlined" sx={{mt: 1}} fullWidth>Build All Same Location</LoadingButton>
                        <LoadingButton
                            onClick={showCopyToModal}
                            variant="outlined"
                            fullWidth
                            sx={{mt: 1}}
                        >
                            Build to Other Client
                        </LoadingButton>
                        <PlanCopyToModal
                          open={displayCopyToModal}
                          buildtype="future_plan"
                          onClose={() => setDisplayCopyToModal(false)}
                          formdata={getSubmitData()}
                        />
                    </Box>
                </Box>

                <Box sx={ModifyStyle.loadPlan}>
                    <Box sx={ModifyStyle.loadPlanTitle}>Load Plan</Box>
                    <Box>
                        <Button onClick={() => loadPlan(1)} variant="outlined" fullWidth sx={{mt: 2}}>Load Live Plan</Button>
                    </Box>
                </Box>
                <Box sx={ModifyStyle.tabs}>
                    {
                        tabs.map(item => {
                            const style = item.label === tab? {...ModifyStyle.tab, ...ModifyStyle.activeTab} : ModifyStyle.tab;
                            return <Box onClick={() => onTabChange(item.label)} key={`tab_switch_${item.label}`} sx={style}>{item.label}</Box>
                        })
                    }
                </Box>
                {tabs.map(_tab => <Paper key={`tab_table_${_tab.label}`} hidden={_tab.label !== tab} sx={ModifyStyle.transparentPaper}>
                    <TableContainer sx={ModifyStyle.tabPanel}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                {_tab.titles.map(_item => {
                                    return <TableCell key={`tab_table_title_${_tab.label}_${_item}`}>{_item}</TableCell>
                                })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_tab.values.map((item, index) => {
                                    return <TableRow key={`tab_table_title_${_tab.label}_${index}`}>
                                        {item.map((v, k) => {
                                            return <TableCell key={`tab_table_title_${_tab.label}_${index}_${k}`}>{v}</TableCell> 
                                        })}
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>)}

            </Box>
        </Box>
        <Box sx={ModifyStyle.rightSection}>
            <Grid container direction="column" sx={ModifyStyle.searchArea}>
                <Grid item container spacing={2}>
                    {Object.keys(annualForm).map((key) => {
                        const searchItem = annualForm[key];
                        return (
                            <Grid key={key} item xs={12} sm={6} md={3}>
                                <TextField
                                    onChange={({target}) => updateAnnualForm(key, {value: target.value})}
                                    onBlur={(e) => onAnnualFormBlur(key, searchItem)}
                                    value={searchItem.value}
                                    label={searchItem.label}
                                    size="small"
                                    fullWidth
                                />
                                <Box classes='searchChips'></Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item container sx={ModifyStyle.searchButtons}>
                    <Button size={'small'} variant="contained"
                            sx={{ml: {xs: 'none', sm: 'auto'}, mt: {xs: 2, sm: 0}}} onClick={getKFlow}>K-FLOW</Button>
                    <Button size={'small'} variant="contained" sx={{ml: 2, mt: {xs: 2, sm: 0}}} onClick={reloadPlan}>UNDO / RELOAD</Button>
                </Grid>
            </Grid>
            <BigTable 
                tableData={tableData}
                displayMonths={displayMonths}
                colorMap={colorMap}
                onInputBlur={(tableKey, innerKey, valIndex) => onBlurTableForm(tableKey, innerKey, valIndex)}
                onInputChange={(e, tableKey, innerKey, valIndex) => updateTableForm(e, tableKey, innerKey, valIndex)}
            />
        </Box>
     </>
}

export default FuturePlanEdit