import React, { useState } from 'react';
import { Box, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const tabsContainerStyle = {
    maxWidth: 250,
    borderBottom: 1,
    borderColor: 'divider'
};

const tabItemStyle = {
    fontSize: '0.75rem',
    minWidth: 10,
    padding: '6px 6px'
};

const tableContainerStyle = {
    maxWidth: '350px',
    width: '350px',
    overflowX: 'auto',
};

const tableStyle = {
    backgroundColor: '#f3f4f5',
}

const cellStyle = {
    padding: '2px 2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const DynamicTable = ({ columns, rows }) => (
    <TableContainer component={Paper} sx={tableContainerStyle}>
        <Table size="small" aria-label="a dense table" sx={tableStyle}>
            <TableHead>
                <TableRow>
                    {columns.map((column, index) => (
                        <TableCell key={index} sx={cellStyle}>{column}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index}>
                        {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex} sx={cellStyle}>{cell}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const TargetBox = ({ data }) => (
    <DynamicTable 
        columns={["Target", "Actual", "Plan"]}
        rows={[
            ["Cash", data?.cash?.actual, data?.cash?.plan],
            ["GMROI", data?.GMROI?.actual, data?.GMROI?.plan],
            ["CMROI", data?.CMROI?.actual, data?.CMROI?.plan],
            ["MMU", data?.MMU?.actual, data?.MMU?.plan],
            ["Efficiency", data?.Efficiency?.actual, data?.Efficiency?.plan],
        ]}
    />
);

const TrendBox = ({ data }) => (
    <DynamicTable 
        columns={["Metric", "Actual", "Trend", "Plan", "% of Plan"]}
        rows={[
            ["Sales", data?.sales?.actual, data?.sales?.trend, data?.sales?.plan, data?.sales?.percentOfPlan],
            ["Md", data?.markdowns?.actual, data?.markdowns?.trend, data?.markdowns?.plan, data?.markdowns?.percentOfPlan],
            ["Inv", data?.inventory?.actual, data?.inventory?.trend, data?.inventory?.plan, data?.inventory?.percentOfPlan],
            ["Calc", data?.calc?.actual, data?.calc?.trend, data?.calc?.plan, data?.calc?.percentOfPlan],
            ["Calc3", data?.calc3?.actual, data?.calc3?.trend, data?.calc3?.plan, data?.calc3?.percentOfPlan],
            ["Linear", data?.linear?.actual, data?.linear?.trend, data?.linear?.plan, data?.linear?.percentOfPlan],
            ["L12 Sales", data?.L12?.actual, data?.L12?.trend, data?.L12?.plan, data?.L12?.percentOfPlan],
        ]}
    />
);

const Box306090 = ({ data }) => (
    <DynamicTable 
        columns={["Metric", "Actual", "Plan", "Act % Plan"]}
        rows={[
            ["Cash", data?.cash?.actual, data?.cash?.plan, data?.cash?.plan ? (data?.cash?.actual / data?.cash?.plan * 100).toFixed(2) + '%' : 'N/A'],
            ["Inflow", data?.inflow?.actual, data?.inflow?.plan, data?.inflow?.plan ? (data?.inflow?.actual / data?.inflow?.plan * 100).toFixed(2) + '%' : 'N/A'],
            ["Sales", data?.sales?.actual, data?.sales?.plan, data?.sales?.plan ? (data?.sales?.actual / data?.sales?.plan * 100).toFixed(2) + '%' : 'N/A'],
            ["Markdowns", data?.markdowns?.actual, data?.markdowns?.plan, data?.markdowns?.plan ? (data?.markdowns?.actual / data?.markdowns?.plan * 100).toFixed(2) + '%' : 'N/A'],
            ["Freshness", data?.freshness?.actual, data?.freshness?.plan, data?.freshness?.plan ? (data?.freshness?.actual / data?.freshness?.plan * 100).toFixed(2) + '%' : 'N/A'],
        ]}
    />
);

const Header = ({ selectedTab, setSelectedTab }) => (
    <Box sx={{ overflow: 'auto' }}>
        <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={tabsContainerStyle}
        >
            <Tab label="Target" value="target" sx={tabItemStyle} />
            <Tab label="Trend" value="trend" sx={tabItemStyle} />
            <Tab label="30" value="30" sx={tabItemStyle} />
            <Tab label="60" value="60" sx={tabItemStyle} />
            <Tab label="90" value="90" sx={tabItemStyle} />
        </Tabs>
    </Box>
);

const TrendTargetBox = ({ data }) => {
    const [selectedTab, setSelectedTab] = useState('trend');

    return (
        <Box>
            <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab === 'target' && <TargetBox data={data.Targets} />}
            {selectedTab === 'trend' && <TrendBox data={data.Trend} />}
            {selectedTab === '30' && <Box306090 data={data['30']} />}
            {selectedTab === '60' && <Box306090 data={data['60']} />}
            {selectedTab === '90' && <Box306090 data={data['90']} />}
        </Box>
    );
}

export default TrendTargetBox;
