import React from "react"
import ClientService from "../../../../services/ClientService"
import ClientContext from "../../../../providers/CurrentClientProvider"
import {Typography, TextField, Button, Card, CardHeader, CardContent} from "@mui/material"
import {useForm} from 'react-hook-form'
import { useNavigate } from "react-router"
import AuthProvider from "../../../../providers/AuthProvider"

const cssInJs = {
    header: {
        backgroundColor: "#422e52",
        color: "#fff",
        fontSize: '14px'
    },
    subheader: {
        color: "#fff",
        fontSize: '13px'
    },
    warning: {
        backgroundColor: '#FFE264',
        marginTop: '-16px',
        marginBottom: 0,
        marginLeft: '-16px',
        marginRight: '-16px',
        padding: '10px',
        fontSize: '13px'
    },
    textInput: {
        marginBottom: '15px'
    }
}

const GeneralClientCreateForm = (props) => {
    const client_context = ClientContext.useClient()
    const [loading, setLoading] = React.useState(true)
    const auth = AuthProvider.useAuth()
    const [isAdmin, setIsAdmin] = React.useState(false)
    const [validClientCode, setValidClientCode] = React.useState(false)
    const navigate = useNavigate()

    React.useEffect(() => {
        if (auth.checkPermission('role-create')) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [auth.user]);

    const {
        register,
        setValue,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        formState: {
            errors,
            isValid,
            isValidating,
            dirtyFields,
            isSubmitting
        }
    } = useForm({
        mode: 'onChange'
    })

    async function onSubmit(data) {
        try {
            // if not admin add "assignUserToClient" to post data
            if (!isAdmin) {
                // assign the auth user to the client as an affiliate so they have access
                data.assignUserToClient = true
            }

            const sample_client_code = await ClientService.createNewClient(data)
            client_context.setClient(sample_client_code)

            // redirect to setup page
            navigate('/client/setup')
        } catch (e) {
            console.log('error', e)
        }
    }

    React.useEffect(() => {
        // on load find the next available client code
        const findNextClientCode = async () => {
            const nextClientCode = await ClientService.getNextClientCode()
            return nextClientCode
        }
        findNextClientCode().then((nextClientCode) => {
            // set the default values for the form
            const defaultValues = {
                client_code: nextClientCode,
                name: ''
            }

            // set the default values for the form
            Object.keys(defaultValues).forEach((key) => {
                register(key)
                setValue(key, defaultValues[key])
            })

            setLoading(false)
        })
    }, [])

    const formDataWatched = watch(
        'client_code',
        'name'
    )

    const isHeaderVisible = formDataWatched.client_code && formDataWatched.name
    const isWarningVisible = 'client_code' in dirtyFields && 'name' in dirtyFields
    const isSubmitButtonDisabled = !(isValid && !isSubmitting && !isValidating)

    return (
        <Card>
            {isHeaderVisible && <CardHeader
                title={formDataWatched.name}
                subheader={formDataWatched.client_code}
                subheaderTypographyProps={{
                    sx: cssInJs.subheader
                }}
                sx={cssInJs.header}
            />}
            <CardContent>
                {isWarningVisible && <Typography sx={cssInJs.warning}>You have unsaved changes, please hit save before
                    continuing.</Typography>}
                {loading ?
                    <Typography>Loading...</Typography>
                : <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Typography variant="h4" gutterBottom>
                            General
                        </Typography>
                        <div>
                            <TextField
                                id="standard-basic"
                                label="Client code"
                                variant="standard"
                                disabled={!isAdmin}
                                fullWidth
                                value={watch('client_code')}
                                sx={cssInJs.textInput}
                                {...register('client_code', {
                                    required: "Code is required",
                                    validate: async (client_code) => {
                                        setValidClientCode(false)
                                        if (client_code === formDataWatched.client_code) {
                                            return errors.client_code ? errors.client_code.message : true
                                        }

                                        const _client = await ClientService.getClientByCode(client_code, 1)
                                        if (_client) {
                                            setError('client_code', {
                                                type: 'manual',
                                                message: `Client Code Exists: ${client_code}`
                                            });
                                            return;
                                        }

                                        setValidClientCode(true)
                                        clearErrors('client_code');
                                    }
                                })}
                                error={Boolean(errors.client_code)}
                                helperText={errors.client_code?.message}
                            />
                        </div>

                        <div>
                            <TextField
                                id="standard-basic"
                                label="Name"
                                variant="standard"
                                fullWidth
                                {...register('name', {
                                    required: "Name is required"
                                })}
                                sx={cssInJs.textInput}
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                            />
                        </div>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isSubmitButtonDisabled || !validClientCode}
                        >
                            {isSubmitting || isValidating ? 
                                <span>Working...</span>
                            :   <span>Save Client Profile</span>}
                        </Button>
                    </form>
                }
            </CardContent>
        </Card>
    )
}

export default GeneralClientCreateForm
