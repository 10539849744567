import {useState, useEffect, useMemo} from 'react';
import {
  Modal,
  Box,
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'underscore';
import ClientService from '../../services/ClientService';
import { LoadingButton } from '@mui/lab';
import PlanService from '../../services/PlanService';
import MessageContext from '../../providers/GlobalTip';
import SimpleSearchableClientDropdown from '../client/SimpleSearchableClientDropdown';

function PlanCopyFromModal(props) {
  const {
    open,
    onClose,
    setOverridePlanData,
    clientList
  } = props;
  const [selectClient, setSelectClient] = useState(null);
  const [selectClientDetails, setSelectClientDetails] = useState(null);
  const [selectLocation, setSelectLocation] = useState(null);
  const [selectClass, setSelectClass] = useState(null);
  const [fetchingPlanData, setFetchingPlanData] = useState(false);
  const messageContext = MessageContext.useMessage();

  useEffect(() => {
    return () => {
      setSelectClient(null);
      setSelectClientDetails(null);
      setSelectLocation(null);
      setSelectClass(null);
    }
  }, [open])

  useEffect(() => {
    setSelectClientDetails(null);

    if (selectClient) {
      ClientService.getClientByCode(selectClient.ClientCode).then((data) => {
        setSelectClientDetails(data);
      }).catch((err) => {
        messageContext.error(err.message);
      });
    }

    setSelectLocation(null);
    setSelectClass(null);
  }, [selectClient])

  const filteredClasses = useMemo(() => {
    if (selectClientDetails && selectLocation) {
      const availableClassIdList = _.pluck(selectClientDetails.location_classes.filter(i => i.client_location_id==selectLocation.client_location_id && PlanService.isPlannable(i)), 'clientclass_id');
      return _.sortBy(selectClientDetails.classes.filter(i => availableClassIdList.indexOf(parseInt(i.id))>-1), 'code');
    } else {
      return [];
    }
  }, [selectLocation, selectClientDetails])

  const fetchPlanData = async () => {
    setFetchingPlanData(true);
    if (selectClientDetails && selectLocation && selectClass) {
      const selectedLocationClassId = selectClientDetails.location_classes.find(i => i.client_location_id==selectLocation.client_location_id && i.clientclass_id==selectClass.id).id;

      if (!selectedLocationClassId) {
        messageContext.error('Invalid location class');
        return;
      }

      PlanService.getPlanDataForCopyFrom(selectClientDetails.Id, selectedLocationClassId).then((data) => {
          // if data has first index (0), set the plan data
          if (data[0]) {
            setOverridePlanData(data[0]);

            // close
            onClose();
          } else {
            messageContext.show('No plan data found');
          }
      }).catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            messageContext.show(err.response.data.message);
          } else {
            messageContext.error("Something went wrong");
          }
      });
    }
    setFetchingPlanData(false);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={css.container}>
        <Typography variant="h6">Copy from</Typography>
        {!clientList && (
          <Box sx={css.loadingSection}>
            <CircularProgress />
          </Box>
        )}
        <SimpleSearchableClientDropdown
          clients={clientList}
          selectedClient={selectClient}
          setSelectedClient={setSelectClient}
          styleOverrides={{mt: 2}}
        />

        {_.isArray(clientList) && selectClient && !selectClientDetails && (
          <Box sx={css.loadingSection}>
            <CircularProgress />
          </Box>
        )}
        {selectClientDetails && (
          <Autocomplete
            getOptionLabel={(item) => `[${item.location_code}] ${item.location_name}`}
            options={selectClientDetails.locations}
            value={selectLocation}
            sx={{mt: 2}}
            onChange={(e, newValue) => setSelectLocation(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Location" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_location_${option.client_location_id}`}>[{option.location_code}] {option.location_name}</li>
            )}
          />
        )}
        {selectClientDetails && selectLocation && (
          <Autocomplete
            getOptionLabel={(item) => `[${item.code}] ${item.name}`}
            options={filteredClasses}
            value={selectClass}
            sx={{mt: 2}}
            onChange={(e, newValue) => setSelectClass(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Class" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={`client_class_${option.id}`}>[{option.code}] {option.name}</li>
            )}
          />
        )}
        <Box sx={{mt: 2}}>
            <LoadingButton
                loading={fetchingPlanData}
                size="large"
                variant="contained"
                onClick={fetchPlanData}
                disabled={!selectClientDetails || !selectLocation || !selectClass || fetchingPlanData}
            >
              Live Plan
            </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default PlanCopyFromModal;

const css = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    px: 3,
    py: 2,
  },
  loadingSection: {
    textAlign: 'center',
    mt: 2,
  },
}