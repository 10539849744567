import TableauViz from "../components/tableau/TableauViz";
import { setUserId } from '@amplitude/analytics-browser';
import AuthProvider from "../providers/AuthProvider";
import React from "react";

function Home(props) {
	const auth = AuthProvider.useAuth()

	React.useEffect(() => {
		if (auth.user) {
			setUserId(auth.user.id)
		}
	}, [auth.user])

	React.useEffect(() => {
		document.title = 'Retail ORBIT: Home'
	}, [])

	return (
	<>
		<TableauViz />
	</>
	)
}

export default Home