import React, { useState, useRef, useEffect } from "react";
import ModifyStyle from "../../components/plan/PlanStyle";
import PlanService from "../../services/PlanService";
import MessageContext from "../../providers/GlobalTip";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FuturePlanEditModal from "../modals/plans/FuturePlanEditModal";
import {
  Grid,
  Button,
  Box,
  CircularProgress,
  ButtonGroup,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  LivePlanAnnualForm,
  LivePlanTableDataMap,
} from "../../models/LivePlanModel";
import cloneDeep from "lodash.clonedeep";
import _ from "underscore";
import __ from "lodash";
import PlanCalculatorService from "../../services/PlanCalculatorService";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PlanCopyFromModal from "./PlanCopyFromModal";
import PlanCopyToModal from "./PlanCopyToModal";
import {
  HotKeysContext,
} from "../../providers/HotKeysProvider";
import PlanChart from "./PlanChart";
import { handleTrackError, trackEvent } from "../../services/AmplitudeService";
import PlanEditTable from "./PlanEditTable";
import TrendTargetBox from "./TrendTargetBox";

function LivePlanEdit(props) {
  const {
    epochMonth,
    client,
    onPlanDataLoad,
    clientList,
    setShowHeaderContainer,
    isFuturePlan,
    planId,
    locationClassId,
    addPlanVersionToClassDropdown,
    nextClass,
    nextLocation,
    forceReload,
    setForceReload,
  } = props;
  const [displayMonths, setDisplayMonths] = useState([]);
  const messageContext = MessageContext.useMessage();
  const [displayCopyFromModal, setDisplayCopyFromModal] = useState(false);
  const [displayCopyToModal, setDisplayCopyToModal] = useState(false);
  const intervalRef = useRef();
  const isCopy = JSON.parse(localStorage.getItem('showLoadCopyContainer'))
  const isTarget = JSON.parse(localStorage.getItem('showTargetContainer'))
  const isGraph = JSON.parse(localStorage.getItem('showGraphContainer'))
  const [showLoadCopyContainer, setShowLoadCopyContainer] = useState(isCopy === null ? true : isCopy);
  const [showTargetContainer, setShowTargetContainer] = useState(isTarget === null ? true : isTarget);
  const [showGraphContainer, setShowGraphContainer] = useState(isGraph === null ? true : isGraph);
  const [futurePlanModalOpen, setFuturePlanModalOpen] = useState(false);

  const [chartData, setChartData] = useState(new Map());
  const [overridePlanData, setOverridePlanData] = useState(null); // used during copy from to override the plan data shown in the table without updating the plan id state

  const [tabs, setTabs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('showLoadCopyContainer', JSON.stringify(showLoadCopyContainer));
    localStorage.setItem('showTargetContainer', JSON.stringify(showTargetContainer));
    localStorage.setItem('showGraphContainer', JSON.stringify(showGraphContainer));
  }, [showLoadCopyContainer, showTargetContainer, showGraphContainer]);

  useEffect(() => {
    return () => {
      // componentwillunmount in functional component.
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (!displayMonths.length && epochMonth) {
        const startMonth = PlanService.convertEpochMonthToDate(epochMonth);

        // get this and next 11 months, format as MMM
        let months = [];
        for (let i = 0; i < 12; i++) {
            months.push(moment(startMonth).add(i, 'months').format('MMM'));
        }
        setDisplayMonths(months);
    }
  }, [epochMonth]);

  const toggleCopyPanel = () => {
    setShowLoadCopyContainer(showLoadCopyContainer => !showLoadCopyContainer);
  }
  const toggleTargetPanel = () => {
    setShowTargetContainer(showTargetContainer => !showTargetContainer);
  }
  const toggleGraphPanel = () => {
    setShowGraphContainer(showGraphContainer => !showGraphContainer);
  }
  const toggleHeaderPanel = () => {
    setShowHeaderContainer(showHeaderContainer => !showHeaderContainer);
  }

  const [savingPlan, setSavingPlan] = useState(false);

  const updatePlanV2 = async (data, annuals, asFuture = false) => {
    // check if any inflow are negative
    let hasMinusInflow = false;

    if (!data["InflowRetailPlan"] || !data["InflowCostPlan"]) {
      messageContext.show("Inflow data is missing");
      return false;
    }

    data["InflowRetailPlan"].forEach((item) => {
      if (item < 0) {
        hasMinusInflow = true;
      }
    });

    data["InflowCostPlan"].forEach((item) => {
      if (item < 0) {
        hasMinusInflow = true;
      }
    });

    if (hasMinusInflow) {
      messageContext.show("Negative inflow error!");
      return false;
    }

    if (asFuture) {
      data["isFuturePlan"] = true;
      data["planVersion"] = 1000; // set to 1000 so back end logic can deal with plan version iteration
    }

    setSavingPlan(true);

    try {
      // append annuals to data
      data.annuals = annuals;

      // check which models are being used
      let models = {
        "sales_model_type": 0,
        "markdowns_model_type": 0,
        "turn_model_type": 0,
        "imu_model_type": 0,
      };

      // if annual sales plan matches any of the model types, set the type
      switch (data.annuals?.annualSalesPlan) {
        case data.annuals?.annualSalesL12:
          models.sales_model_type = 1;
          break;
        case data.annuals?.linearSalesModel:
          models.sales_model_type = 2;
          break;
        case data.annuals?.calc1SalesModel:
          models.sales_model_type = 3;
          break;
        case data.annuals?.calc3SalesModel:
          models.sales_model_type = 4;
          break;
        default:
            models.sales_model_type = 0;
      }

      switch (data.annuals?.annualMarkdownsPlan) {
        case data.annuals?.annualMarkdownsL12:
          models.markdowns_model_type = 2;
          break;
      }

      switch (data.annuals?.annualTurnPlan) {
        case data.annuals?.annualTurnActual:
          models.turn_model_type = 1;
          break;
        case data.annuals?.turnLm:
          models.turn_model_type = 2;
          break;
      }

      switch (data.annuals?.annualImuPlan) {
        case data.annuals?.imuActual:
          models.imu_model_type = 2;
          break;
        case data.annuals?.imuLm:
          models.imu_model_type = 1;
          break;
      }

      data.modelTypes = models;

      let response = await PlanService.updatePlanV2(client.Id, planId, data);

      messageContext.showSuccess("Updated successfully. Attempting to load plan...");
      addPlanVersionToClassDropdown(response.result.plan_id, response.result.plan_version, data.clientLocationId, data.locationClassId, data.serviceTypeId)

      trackEvent('Planning Pages Event', {
        action: 'Update Plan',
        clientCode: client?.ClientCode,
        newPlanVersion: response.result.plan_version,
      })
    } catch (e) {
      if (e?.response?.data?.message) {
        messageContext.show(e.response.data.message);
      } else {
        messageContext.show("Unknown Error");
      }
      console.log(e)
    }

    setSavingPlan(false);
  };

  const showCopyToModal = () => {
    setDisplayCopyToModal(true);
  };

  const hotKeysContext = {
    // clientBasicData,
    updatePlanV2,
    nextLocation,
    nextClass,
    setDisplayCopyFromModal,
    showCopyToModal,
    // createFuturePlan,
    // removeFuturePlan,
    toggleCopyPanel,
    toggleTargetPanel,
    toggleGraphPanel,
    toggleHeaderPanel,
  };

  return (
    <>
      <Box sx={ModifyStyle.leftSection}>
        <Box sx={ModifyStyle.leftContent}>
          {/* <Box sx={{paddingLeft: '10px', mt: 2, pt: 1, fontSize: '12px'}}>
            Date created: {moment(planData?.plan_info.created_date.date).format('Y-MM-DD')} <br/>
            Source: {planData?.plan_info.source}
          </Box> */}
          <Box sx={ModifyStyle.loadPlan}>
            <ButtonGroup id={'left-button-group'}
              variant="outlined"
              size="small"
              sx={{ mb: 2 }}
              aria-label="outlined button group"
            >
              <Button variant={showLoadCopyContainer ? "contained" : "outlined"} onClick={() => toggleCopyPanel()}><ContentCopyIcon  sx={{ fontSize: '12px'}} />  Copy</Button>
              <Button variant={showTargetContainer ? "contained" : "outlined"} onClick={() => toggleTargetPanel()}><TrackChangesIcon  sx={{ fontSize: '12px' }} />  Target</Button>
              <Button variant={showGraphContainer ? "contained" : "outlined"} onClick={() => toggleGraphPanel()}><InsertChartIcon  sx={{ fontSize: 'small' }} />  Chart</Button>
            </ButtonGroup>
            {showLoadCopyContainer &&  <Box id={'load-copy-container'}>
              <Button
                onClick={() => setDisplayCopyFromModal(true)}
                fullWidth
                variant="outlined"
              >
                Load Plan
              </Button>
              <PlanCopyFromModal
                open={displayCopyFromModal}
                onClose={() => setDisplayCopyFromModal(false)}
                clientList={clientList}
                setOverridePlanData={setOverridePlanData}
              />
              {isFuturePlan ? (
                <>
                  {/* <LoadingButton
                    onClick={showCopyToModal}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Copy To
                  </LoadingButton>
                  <PlanCopyToModal
                    open={displayCopyToModal}
                    annualform={annualForm}
                    buildtype="live_plan"
                    onClose={() => setDisplayCopyToModal(false)}
                  />
                  <LoadingButton
                    onClick={() => setFuturePlanModalOpen(!futurePlanModalOpen)}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Edit Future Open Plan
                  </LoadingButton>
                  <FuturePlanEditModal
                    open={futurePlanModalOpen}
                    onClose={() => setFuturePlanModalOpen(false)}
                    planSettingId={planId}
                    epochMonth={epochMonth}
                  /> */}
                </>
              ): null}
            </Box>}
          </Box>
          <TrendTargetBox data={tabs} />
          {showGraphContainer && chartData && chartData.size > 0 && <Box id={"graph-container"} sx={ModifyStyle.graphContainer}>
            <PlanChart sx={{ mt: 2 }} displayMonths={displayMonths} chartData={chartData} />
          </Box>}
        </Box>
      </Box>

      <Box sx={ModifyStyle.rightSection}>
        <Grid container direction="column" sx={ModifyStyle.searchArea}>
          <Grid item container sx={ModifyStyle.searchButtons}>
            {/* <Button
              size={"small"}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={clearCache}
            >
              Clear Cache
            </Button>
            <Button
              size={"small"}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={reloadPlan}
            >
              UNDO / RELOAD
            </Button> */}
            {/* <Button
              size={"small"}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={getKFlow}
            >
              K-FLOW
            </Button>
            <Tooltip title={"Copy Sales & Markdown Actuals % to Plan"}>
              <Button
                size={"small"}
                variant="contained"
                sx={{ mr: 2 }}
                onClick={copyBothActuals}
              >
                COPY ACTUALS
              </Button>
            </Tooltip> */}
            {/* <ButtonGroup variant="outlined" sx={{ mr: 2 }}>
              {[1, 2, 3].map((backYear) => {
                const _year = moment().subtract(backYear, "years");
                const _yearMonth = _year.format("YYYYMM");
                return (
                  <LoadingButton
                    key={`backYear${backYear}`}
                    loading={loadingHistoricalData}
                    variant={
                      historicalPeriodDatas.find(
                        (item) => item.yearmonth == _yearMonth
                      )
                        ? "contained"
                        : "outlined"
                    }
                    disabled={
                      _.pluck(
                        clientBasicData?.historical_periods,
                        "yearmonth"
                      ).indexOf(_yearMonth) == -1
                    }
                    onClick={() => toggleHistoricalData(_yearMonth)}
                  >
                    {_year.format("YYYY")}
                  </LoadingButton>
                );
              })}
            </ButtonGroup> */}
          </Grid>
        </Grid>
        <Box sx={{ pb: 7 }}>
          <HotKeysContext.Provider value={hotKeysContext}>
            <PlanEditTable
              nextClass={nextClass}
              nextLocation={nextLocation}
              savingPlan={savingPlan}
              onUpdate={updatePlanV2}
              client={client}
              planId={planId}
              locationClassId={locationClassId}
              epochMonth={epochMonth}
              setChartData={setChartData}
              displayMonths={displayMonths}
              messageContext={messageContext}
              tabs={tabs}
              setTabs={setTabs}
              overridePlanData={overridePlanData}
              forceReload={forceReload}
              setForceReload={setForceReload}
            />
          </HotKeysContext.Provider>
        </Box>
      </Box>
    </>
  );
}

export default LivePlanEdit;
