import * as React from "react";
import Request from './Request'

const storage_user_key = 'current_user'

const AuthService = {
	async logout() {
		try {
			await Request.post('/api/logout');
			localStorage.removeItem(storage_user_key)
			localStorage.removeItem('access_token')
		} catch (e) {
			console.error('Logout failed:', e);
			throw e;
		}
	},
	setCurrentUser( user, ignore_access_token=false ) {
		localStorage.setItem(storage_user_key, JSON.stringify(user))
		if( user.auth && user.auth.access_token && !ignore_access_token ) {
			localStorage.setItem('access_token', user.auth.access_token)
		}
	},
	getLocalUser() {
		let user = localStorage.getItem(storage_user_key)
		return JSON.parse(user)
	},
	setAccessToken(token) {
		localStorage.setItem('access_token', token)
	},
	getAccessToken() {
		return localStorage.getItem('access_token')
	},
	async spoofUser(user_id) {
		return await Request.post(`/api/users/${user_id}/spoof`)
	},
	async forgotPassword(email) {
		return await Request.post('/api/forgot-password', {email})
	},
	async resetPassword(data) {
		return await Request.post('/api/reset-password', data)
	}
}

export default AuthService