import Request from './Request'
import moment from 'moment'
import _ from 'underscore'
import constants from '../constants/constant'

const PlanService = {
	// Object Pattern for Decimal Precision
	settings: {
		decimalPrecision: 1
	},
	/**
	 * Formats a number to the specified decimal precision
	 * @param {number} value - The number to format
	 * @param {number} precision - Optional precision override (defaults to settings.decimalPrecision)
	 * @returns {number} - The formatted number as a Number type
	 */
	format(value, precision = null) {
		const usePrecision = precision !== null ? precision : this.settings.decimalPrecision;
		if (isNaN(value) || !isFinite(value)) {
			return 0;
		}
		return Number(parseFloat(value).toFixed(usePrecision));
	},

	/**
	 * Updates the default decimal precision setting
	 * @param {number} newPrecision - The new precision value to use
	 */
	updateDecimalPrecision(newPrecision) {
		this.settings.decimalPrecision = newPrecision;
	},
	async getClientLivePlans(clientId, epochMonth = null) {
		// if epochMonth is provided add to params
		let params = {}
		if (epochMonth) {
			params['epochMonth'] = epochMonth
		}
		try {
			return await Request.get(`/api/clients/${clientId}/plans/live`, params)
		} catch (error) {
			console.log(error)
			return null
		}
	},

	async getLocationClassBaseInfo(client_id, location_class_id) {
		return await Request.get(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}`)
	},
	async saveLocationClassBasePlan(client_id, location_class_id, data) {
		return await Request.post(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/base-plan`, data)
	},
	async bulkSaveLocationClassBasePlan(client_id, location_class_id, data) {
		return await Request.post(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/base-plan/bulk`, data)
	},
	async bulkSaveClientBasePlan(client_id, data) {
		return await Request.post(`/api/clients/${client_id}/plans/base-plan/bulk`, data)
	},
	async getLocationClassBasePlanDetail(client_id, location_class_id, start_date=null) {
		let params = {}
		if( start_date ) {
			params['start_date'] = start_date
		}
		return await Request.get(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/base-plan-detail`, params)
	},
	async getLocationClassHistoryPlanDetail(client_id, location_class_id) {
		return await Request.get(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/history-plan-detail`)
	},
	async getLocationClassLivePlanDetail(client_id, location_class_id, response_type='') {
		return await Request.get(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/live-plan-detail?response_type=${response_type}`)
	},
	async getLocationClassFuturePlanDetail(client_id, location_class_id) {
		return await Request.get(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/future-plan-detail`)
	},
	async saveLocationClassFuturePlan(client_id, location_class_id, data) {
		return await Request.post(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/future-plan`, data)
	},
	async bulkSaveLocationClassFuturePlan(client_id, location_class_id, method, data) {
		return await Request.post(`/api/clients/${client_id}/plans/locationclasses/${location_class_id}/future-plan/bulk/${method}`, data)
	},
	async bulkSaveClientFuturePlan(client_id, method, data) {
		return await Request.post(`/api/clients/${client_id}/plans/future-plan/bulk/${method}`, data)
	},
	async getLivePlanDetail(client_id, plan_id, plan_version) {
		return await Request.get(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}/plan-detail`)
	},
	async getLivePlanDetailV2(clientId, planId, epochMonth, locationClassId) {
		return await Request.get(`/api/clients/${clientId}/plans/v2/${epochMonth}/${locationClassId}/${planId}/details`)
	},
	async getPlanDataForCopyFrom(clientId, locationClassId, epochMonth = null) {
		if (!epochMonth) {
			return await Request.get(`/api/clients/${clientId}/plan-copy-from/${locationClassId}`)
		}
	},
	async getLivePlanDetailClearCache(client_id, plan_id, plan_version) {
		return await Request.get(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}/clear-cache`)
	},

	/**
	 * Get plan detail targets (broken out to aid performance and allow partial load)
	 *
	 * @param {string} client_id
	 * @param {string} plan_id
	 * @param {string} plan_version
	 *
	 * @returns {object}
	 */
	async getLivePlanDetailTargets(client_id, plan_id, plan_version) {
		return await Request.get(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}/plan-detail/targets`)
	},
	async getLivePlanDetailTargetsClearCache(client_id, plan_id, plan_version) {
		return await Request.get(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}/plan-detail/targets-clear-cache`)
	},

	async getLivePlanBaseInfo(client_id, plan_id, plan_version) {
		return await Request.get(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}`)
	},
	async makePlanLive(client_id, plan_id, plan_version) {
		return await Request.post(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}/live`)
	},
	async removePlan(client_id, plan_id, plan_version) {
		return await Request.post(`/api/clients/${client_id}/plans/${plan_id}/${plan_version}/remove`)
	},
	async savePlan(client_id, plan_id, data, plan_version=null, future=false) {
		let query_string = ''
		if( plan_version ) {
			query_string += `&version=${plan_version}`
		}
		if( future ) {
			query_string += `&future=${future}`
		}
		return await Request.post(`/api/clients/${client_id}/plans/${plan_id}?${query_string}`, data)
	},
	async getCopyPlans() {
		return await Request.get(`/api/copy-plans`)
	},
	async getCopyPlanFiles() {
		return await Request.get(`/api/copy-plan/files`)
	},
	async getCopyPlanDetail(id) {
		return await Request.get(`/api/copy-plans/${id}`)
	},
	async getCopyPlanFileDetail(id) {
		return await Request.get(`/api/copy-plan/files/${id}`)
	},
	async getRecentEpochmonth(offset=2) {
		const d1 = new moment("1970-01-01");
		const d2 = new moment();
		const corrent_epochmonth = d2.diff(d1, 'months');
		let data = []
		_.range(offset*2+1).forEach(i => {
			const _d = new moment().add(i-offset, 'months')
			data.push({
				date: _d,
				epochmonth: _d.diff(d1, 'months'),
				current: _d.diff(d1, 'months') == corrent_epochmonth
			})
		})
		return data
	},
	async uploadCopyPlanFile(file) {
		let formData = new FormData()
		formData.append("data", file)
		return await Request.formPost(`/api/copy-plan/files`, formData)
	},
	async createCopyPlanLine(data) {
		return await Request.post(`/api/copy-plan/line`, data)
	},
	async copyPlanReprocess(id) {
		return await Request.post(`/api/copy-plan/line/${id}/reprocess`)
	},
	async copyPlanFileReprocess(id) {
		return await Request.post(`/api/copy-plan/files/${id}/reprocess`)
	},
	async downloadCopyPlanFile(id) {
		window.open(`${constants.BACKEND_DOMAIN}/api/copy-plan/files/${id}/download?_access_token=${localStorage.getItem('access_token')}`, '_blank')
	},
	isPlannable(locationClass) {
        return locationClass.servicetypeid=='1' || locationClass.servicetypeid=='2'
    },
    async copyToBasePlanFromLocationClass(params) {
    	return Request.post(`/api/base-plan/copy-to`, params);
    },
    async copyToLivePlan(params) {
    	return Request.post(`/api/live-plan/copy-to`, params);
    },
	async checkPlan(client_id, planId, planVersion) {
		return Request.get(`/api/clients/${client_id}/plans/${planId}/${planVersion}/exists`);
	},

	/**
	 * Return latest version of plan for plan_id
	 * 
	 * @param {string} client_id
	 * @param {string} plan_id
	 * 
	 * @returns {object}
	 */
	async getLatestPlanVersionForPlan(client_id, plan_id) {
		return Request.get(`/api/clients/${client_id}/max-plan-version/${plan_id}`);
	},

	/**
	 * Get next 12 months for plan setting id and epoch month
	 * 
	 * @param {string} plan_setting_id
	 * @param {string} epoch_month
	 * 
	 * @returns {object}
	 */
	async getNext12MonthsForPlanSetting(plan_setting_id, epoch_month) {
		return Request.get(`/api/plan-setting/next-12-months`, { plan_setting_id, epoch_month });
	},

	/**
	 * Update next 12 months with new open_values_json
	 * 
	 * @param {string} plan_setting_id
	 * @param {string} epoch_month
	 * @param {string} open_values
	 * @param {string} open_epoch_month
	 * 
	 * @returns {object}
	 */
	async updateNext12MonthsForPlanSetting(plan_setting_id, epoch_month, open_values, open_epoch_month) {
		return Request.post(`/api/plan-setting/next-12-months/open_values_json`, { plan_setting_id, epoch_month, open_values, open_epoch_month });
	},

	/**
	 * Upload mass copy plan file
	 * 
	 * @param {string} file
	 * @param {string} client_id
	 * 
	 * @returns {object}
	 */
	async uploadMassUploadPlanFile(file, client_id) {
		let formData = new FormData()
		formData.append("file", file)
		return Request.formPost(`/api/clients/${client_id}/plans/upload/mass-copy-plan-file`, formData)
	},

	/**
	 * Update plan v2 (uses new UI and backend)
	 * 
	 * @param {string} clientId
	 * @param {string} planId
	 * @param {array} data
	 * 
	 * @returns {object}
	 */
	async updatePlanV2(clientId, planId, data) {
		return Request.post(`/api/clients/${clientId}/plans/v2/${planId}/update-plan`, data)
	},

	/**
	 * Convert Epoch Month to Date
	 */
	convertEpochMonthToDate(epochMonth) {
		return moment("1970-01-01").add(epochMonth, 'months').format('YYYY-MM-DD');
	},

	/**
	 * Get today epoch month
	 */
	getTodayEpochMonth() {
		const d1 = new moment("1970-01-01");
		const d2 = new moment();
		return d2.diff(d1, 'months');
	},

	/**
	 * Get epoch month as MMM YY
	 */
	getEpochMonthAsMMMYY(epochMonth) {
		return moment("1970-01-01").add(epochMonth, 'months').format('MMM YY');
	},
}

export default PlanService