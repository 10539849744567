// src/components/IdleTimer.js
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Request from '../services/Request';
import AuthProvider from '../providers/AuthProvider';

const IdleTimer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setUser } = AuthProvider.useAuth();
    const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
        // 2 hours in ms, default if env not set
        const idleTimeout = process.env.REACT_APP_IDLE_TIMEOUT ?
            parseInt(process.env.REACT_APP_IDLE_TIMEOUT) : 7200000;

        let idleTimer = null;

        // Reset the timer on user activity
        const resetTimer = () => {
            clearTimeout(idleTimer);
            setIsIdle(false);
            idleTimer = setTimeout(() => handleTimeout(), idleTimeout);
        };

        // Handle timeout - log the user out
        const handleTimeout = async () => {
            if (location.pathname !== '/login' &&
                location.pathname !== '/forgot-password' &&
                !location.pathname.startsWith('/restore-password')) {
                setIsIdle(true);
                try {
                    // Call logout API
                    await Request.post('/api/logout');
                    // Clear localStorage
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    // Update auth context
                    setUser(null);
                    // Redirect to login
                    navigate('/login', { state: { message: 'You have been logged out due to inactivity.' } });
                } catch (error) {
                    console.error('Logout failed:', error);
                    navigate('/login');
                }
            }
        };

        // Add event listeners for user activity
        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach(event => {
            document.addEventListener(event, resetTimer);
        });

        // Initialize timer
        resetTimer();

        // Cleanup
        return () => {
            clearTimeout(idleTimer);
            events.forEach(event => {
                document.removeEventListener(event, resetTimer);
            });
        };
    }, [navigate, setUser, location.pathname]);

    return null;
};

export default IdleTimer;