import React, { useState, useEffect } from "react";
import ModifyStyle from "./PlanStyle";
import {
  TextField,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Chip,
  Button,
} from "@mui/material";
import numeral from "numeral";
import HotKeysDrawer from "./HotKeysDrawer";
import PlanService from "../../services/PlanService";
import _ from "underscore";
import { LoadingButton } from "@mui/lab";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import Tooltip from '@mui/material/Tooltip';

// Styles for the table
const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
};

const chipsItem = {
    background:'#70778B', 
    color: '#fff',
    cursor: 'pointer',
}

const rightSection = {
    width: '100%',
    pt: {sm: 2},
    pl: {sm: 2},
    mb: 2,
}

const cellStyle = {
    border: '1px solid #ccc',
    padding: '8px',
    textAlign: 'center',
    borderLeft: 'none',
    borderRight: 'none',
    fontSize: '14px',
};

const headerStyle = {
    background: '#f2f2f2',
    fontWeight: 'bold',
    padding: '8px',
    textAlign: 'center',
    borderLeft: 'none',
    borderRight: 'none',
};

const firstColumnStyle = {
    ...cellStyle,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#795298',
    color: 'white',
    midWidth: '75px',
    width: '75px',
};

const editableFieldStyle = {
    padding: '0',
    height: '20px',
};

const actionButton = {
    mr: 1,
};

function calculatePlanTurn (formattedData, annualValues = null, setAnnualValues = null) {
    const inventoryBomPlanAvg = formattedData['InventoryBomPlan'].reduce((a, b) => a + b, 0) / 12;
    const salesPlanAnnual = formattedData['SalesPlan'].reduce((a, b) => a + b, 0);

    const planTurn = PlanService.format((inventoryBomPlanAvg ? salesPlanAnnual / inventoryBomPlanAvg : 0));

    if (annualValues && setAnnualValues) {
        let _annualValues = {...annualValues};
        _annualValues['annualTurnPlan'] = planTurn;

        setAnnualValues(_annualValues);
    }

    return planTurn;
}

function TopHeader({ displayMonths }) {
    return (
        <TableRow
            sx={{borderBottom: '2px solid green'}}
        >
            <TableCell style={headerStyle} sx={{ color: 'green', width: '200px' }}>Sales</TableCell>
            {displayMonths.map((month, index) => (
                <TableCell key={month} style={index === 0 ? firstColumnStyle : headerStyle}>{month}</TableCell>
            ))}
            <TableCell style={headerStyle}>Summary</TableCell>
        </TableRow>
    )
}

function SubHeader({ subheaderName, customColor=null }) {
    return (
        <TableRow
            style={{
                borderBottom: `2px solid ${customColor ? customColor : 'black'}`,
            }}
        >
            <TableCell
                style={headerStyle}
                sx={{
                    color: customColor ? customColor : 'black',
                }}
            >
                {subheaderName}
            </TableCell>
            <TableCell style={firstColumnStyle}></TableCell>
        </TableRow>
    )
}

function CustomTableRow({
    rowName,
    data,
    editableData = null,
    editable=false,
    isPlanRow=false,
    handleInputChange,
    handleBlur,
    useAvg = false,
    customFormatLogic = null,
    copyActualsToPlan = null,
    dataSection = null
}) {

    if (!data) {
        return (
            <TableRow
                sx={{borderBottom: '1px solid #ccc'}}
            >
                <TableCell style={cellStyle}>{rowName}</TableCell>
                <TableCell colSpan={13} style={cellStyle}>No data available</TableCell>
            </TableRow>
        )
    }

    let processedData;
    let summaryData = 0;
    if (!editableData) {
        processedData = Array.from({ length: 12 }, (_, i) => data[i] ?? 0);
        summaryData = useAvg ? PlanService.format((safeDivide(data.reduce((a, b) => a + b, 0), 12))) : PlanService.format((data.reduce((a, b) => a + b, 0)));
    } else {
        processedData = Array.from({ length: 12 }, (_, i) => editableData[i] ?? 0);
        summaryData = useAvg ? PlanService.format((safeDivide(data.reduce((a, b) => a + b, 0), 12))) : PlanService.format((data.reduce((a, b) => a + b, 0)));
    }

    return (
        <TableRow
            style={{
                backgroundColor: isPlanRow ? '#f2f2f2' : 'white'
            }}
        >
            <TableCell style={cellStyle}>
                {rowName}
                {(dataSection === 'Sales' || dataSection === 'Inv' || dataSection === 'MD' ) && (
                <Tooltip title={"Copy Actuals to Plan"}>
                    <Chip sx={{ marginLeft: '10px !important' }}
                        onClick={() => copyActualsToPlan(dataSection)}
                        icon={<CopyAllIcon sx={{ marginRight: '-11px !important' }} />}
                        color="success"
                        size="small"
                    />
                </Tooltip>
                )}
            </TableCell>
            {processedData.map((value, index) => (
                <React.Fragment key={`${index} ${rowName}`}>
                    {editable ? (
                        <TableCell style={index === 0 ? firstColumnStyle : cellStyle}>
                            <TextField
                                id={`${rowName} ${index}`}
                                value={value}
                                style={editableFieldStyle}
                                onChange={(e) => handleInputChange(e, rowName, index)}
                                onBlur={(e) => handleBlur(e, rowName, index)}
                                inputProps={{ 
                                    style: {
                                        textAlign: 'center', 
                                        height: '20px', 
                                        padding: '0', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                        ...(customFormatLogic ? customFormatLogic(value, index) : {})
                                    }, 
                                }}
                            />
                        </TableCell>
                    ) : (
                        <TableCell
                            style={{
                                ...(index === 0 ? firstColumnStyle : cellStyle),
                                ...(customFormatLogic ? customFormatLogic(value, index) : {})
                            }}
                        >
                            {value}
                        </TableCell>
                    )}
                </React.Fragment>
            ))}
            <TableCell style={cellStyle}>{summaryData}</TableCell>
        </TableRow>
    )
}

const rowNameToIndexName = {
    'Plan %': 'SalesPlanPercent',
    'Plan S/S': 'StockToSalesPlan',
    'Plan Inv BOM': 'InventoryBomPlan',
    'Plan % MD': 'MarkdownPlanPercent',
}

function calcInflow(nextInventory, newInventoryBomPlan, salesPlan, markdownPlan) {
    return nextInventory - newInventoryBomPlan + salesPlan + markdownPlan;
}

function calcInflowPlanAtIndex(index, formattedData) {
    const nextInventory = isNaN(formattedData['InventoryBomPlan'][index + 1]) ? formattedData['InventoryBomPlan'][0] : formattedData['InventoryBomPlan'][index + 1];
    const salesPlan = formattedData['SalesPlan'][index] ?? 0;
    const markdownPlan = formattedData['MarkdownPlan'][index] ?? 0;

    formattedData['InflowRetailPlan'][index] = calcInflow(nextInventory, formattedData['InventoryBomPlan'][index], salesPlan, markdownPlan);
    formattedData['InflowCostPlan'][index] = Math.round(formattedData['InflowRetailPlan'][index] * getCostComplement(formattedData));

    return formattedData;
}

function calcAllPlanInflows(formattedData) {
    formattedData['InflowRetailPlan'] = formattedData['InventoryBomPlan'].map((val, index) => {
        const nextInventory = isNaN(formattedData['InventoryBomPlan'][index + 1]) ? formattedData['InventoryBomPlan'][0] : formattedData['InventoryBomPlan'][index + 1];
        const salesPlan = formattedData['SalesPlan'][index] ?? 0;
        const markdownPlan = formattedData['MarkdownPlan'][index] ?? 0;
        return calcInflow(nextInventory, val, salesPlan, markdownPlan);
    });
    formattedData['InflowCostPlan'] = formattedData['InflowRetailPlan'].map(val => Math.round(val * getCostComplement(formattedData)));

    return formattedData;
}

function simpleKFlow(formattedData, setFormattedData, editableData, setEditableData, annualValues, setAnnualValues) {
    let _formattedData = {...formattedData};
    let _editableData = {...editableData};

    // set all stock to sales to 1
    _formattedData['StockToSalesPlan'] = Array.from({ length: 12 }, () => 1);
    _editableData['StockToSalesPlan'] = Array.from({ length: 12 }, () => '1.00');

    _formattedData['InventoryBomPlan'] = _formattedData['SalesPlan'].map((val, index) => val);
    _editableData['InventoryBomPlan'] = _formattedData['SalesPlan'].map(val => val.toString());

    _formattedData = calcAllPlanInflows(_formattedData);

    // balance
    _formattedData, _editableData = balanceAllInflows(_formattedData, setFormattedData, _editableData, setEditableData);
    
    handleAnnualTurnUpdate(annualValues, _formattedData, setFormattedData, _editableData, setEditableData, _formattedData['ServiceTypeId']);
}




function balanceAllInflows(formattedData, setFormattedData, editableData, setEditableData) {
    let _formattedData = {...formattedData};
    let _editableData = {...editableData};
    for (let i = 0; i < 12; i++) {
        let currentInflow = _formattedData['InflowRetailPlan'][i];
        let nextIndex = i + 1 == 12 ? 0 : i + 1;

        if (currentInflow < 0) {
            const nextSales = _formattedData['SalesPlan'][nextIndex] ?? 0;
            _formattedData['InventoryBomPlan'][nextIndex] += Math.abs(currentInflow);
            _formattedData['StockToSalesPlan'][nextIndex] = nextSales ? PlanService.format(_formattedData['InventoryBomPlan'][nextIndex] / nextSales) : 0;
            _editableData['InventoryBomPlan'][nextIndex] = _formattedData['InventoryBomPlan'][nextIndex].toString();
            _editableData['StockToSalesPlan'][nextIndex] = Number(_formattedData['StockToSalesPlan'][nextIndex]).toFixed(2);
            _formattedData['InflowRetailPlan'][i] = 0;
            _formattedData['InflowCostPlan'][i] = 0;
        }
    }

    _formattedData = calcAllPlanInflows(_formattedData);

    setFormattedData(_formattedData);
    setEditableData(_editableData);

    return _formattedData, _editableData;
}

function checkValidChange(value, editableData, setEditableData, formattedData, rowName, index) {
    rowName = rowNameToIndexName[rowName];

    if (!editableData[rowName]) {
        return false;
    }

    if (isNaN(Number(value))) {
        const resetValue = formattedData[rowName][index];

        setEditableData({
            ...editableData,
            [rowName]: {
                ...editableData[rowName],
                [index]: resetValue,
            }
        });
        return false;
    }

    return true;
}

function getCostComplement(formattedData, actual = false) {
    if (!actual) {
        return (1 - (formattedData['imuPlan'] ? formattedData['imuPlan'] : (formattedData['imuActual'] ? formattedData['imuActual'] : 0.5)));
    } else {
        return (1 - (formattedData['imuActual'] ? formattedData['imuActual'] : (formattedData['imuPlan'] ? formattedData['imuPlan'] : 0.5)));
    }
}

function PlanTable({
   formattedData,
   displayMonths,
   setFormattedData,
   editableData,
   setEditableData,
   annualValues,
   setAnnualValues,
   serviceTypeId,
   copyActualsToPlan
}) {
    const handleInputChange = (e, rowName, index) => {
        rowName = rowNameToIndexName[rowName];

        if (!editableData[rowName]) {
            return;
        }

        const newData = [...formattedData[rowName]];
        newData[index] = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value);
        
        let _editableData = {...editableData};
        _editableData[rowName][index] = e.target.value;

        setEditableData(_editableData);
    }
    
    const handleSalesPercentPlanBlur = (e, rowName, index) => {
        // if value is not a number, return
        if (!checkValidChange(e.target.value, editableData, setEditableData, formattedData, rowName, index)) return;

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        // update the sales plan
        const value = Number(e.target.value);
        const salesPlan = formattedData['SalesPlan'][index];
        const annualSalesPlan = annualValues.annualSalesPlan;
        const newSalesPlan = PlanService.format(annualSalesPlan * (value / 100));

        // update the inflow plan
        _formattedData = calcInflowPlanAtIndex(index, _formattedData);

        // update stock to sales
        const newStockToSalesPlan = newSalesPlan ? formattedData['InventoryBomPlan'][index] / newSalesPlan : 0;

        _formattedData['SalesPlan'][index] = Math.round(newSalesPlan);
        _formattedData['SalesPlanPercent'][index] = PlanService.format(editableData['SalesPlanPercent'][index]);
        _formattedData['StockToSalesPlan'][index] = PlanService.format(parseFloat(newStockToSalesPlan));
        _editableData['StockToSalesPlan'][index] = newStockToSalesPlan.toFixed(2);

        setFormattedData(_formattedData);
        setEditableData(_editableData);
    }

    const handleStockToSalesPlanBlur = (e, rowName, index) => {
        if (!checkValidChange(e.target.value, editableData, setEditableData, formattedData, rowName, index)) return;

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        // take new stock to sales plan and update corresponding values (inflow plan, inventory bom plan)
        let newStockToSalesPlan = PlanService.format(e.target.value);
        let salesPlan = formattedData['SalesPlan'][index] ?? 0;
        let markdownPlan = formattedData['MarkdownPlan'][index] ?? 0;
        let newInventoryBomPlan = 0;
        if (salesPlan == 0) {
            newStockToSalesPlan = 0;
            const lastSalesPlan = isNaN(formattedData['SalesPlan'][index - 1]) ? 0 : formattedData['SalesPlan'][index - 1];
            const lastMarkdownPlan = isNaN(formattedData['MarkdownPlan'][index - 1]) ? 0 : formattedData['MarkdownPlan'][index - 1];
            const lastMonthInventory = isNaN(formattedData['InventoryBomPlan'][index - 1]) ? 0 : formattedData['InventoryBomPlan'][index - 1];

            newInventoryBomPlan = lastMonthInventory - lastSalesPlan - lastMarkdownPlan;
        } else {
            newInventoryBomPlan = newStockToSalesPlan * salesPlan;
        }

        const nextInventory = isNaN(formattedData['InventoryBomPlan'][index + 1]) ? formattedData['InventoryBomPlan'][0] : formattedData['InventoryBomPlan'][index + 1];
        const newInflowRetailPlan = calcInflow(nextInventory, newInventoryBomPlan, salesPlan, markdownPlan);
        const newInflowCostPlan = newInflowRetailPlan * getCostComplement(formattedData)

        // calculate for index - 1
        const prevIndex = index - 1 < 0 ? 11 : index - 1;
        const prevInventory = isNaN(formattedData['InventoryBomPlan'][prevIndex]) ? null : formattedData['InventoryBomPlan'][prevIndex];
        if (prevInventory != null) {
            const prevSales = formattedData['SalesPlan'][prevIndex] ?? 0;
            const prevMarkdown = formattedData['MarkdownPlan'][prevIndex] ?? 0;
            const prevInflowRetailPlan = calcInflow(newInventoryBomPlan, prevInventory, prevSales, prevMarkdown);
            const prevInflowCostPlan = prevInflowRetailPlan * getCostComplement(formattedData);

            _formattedData['InflowRetailPlan'][prevIndex] = Math.round(prevInflowRetailPlan);
            _formattedData['InflowCostPlan'][prevIndex] = Math.round(prevInflowCostPlan);
        }

        _formattedData['StockToSalesPlan'][index] = PlanService.format(parseFloat(newStockToSalesPlan));
        _formattedData['InventoryBomPlan'][index] = Math.round(newInventoryBomPlan);
        _editableData['InventoryBomPlan'][index] = newInventoryBomPlan.toFixed(0);
        _editableData['StockToSalesPlan'][index] = PlanService.format(newStockToSalesPlan);
        _formattedData['InflowRetailPlan'][index] = Math.round(newInflowRetailPlan);
        _formattedData['InflowCostPlan'][index] = Math.round(newInflowCostPlan);

        setFormattedData(_formattedData);
        setEditableData(_editableData);
        calculatePlanTurn(_formattedData, annualValues, setAnnualValues);
    }

    const handleMarkdownPlanPercentBlur = (e, rowName, index) => {
        if (!checkValidChange(e.target.value, editableData, setEditableData, formattedData, rowName, index)) return;

        let _formattedData = {...formattedData};

        // update the markdown plan
        const value = PlanService.format(e.target.value);
        const yearlyMarkdowns = (annualValues.annualMarkdownsPlan / 100) * annualValues.annualSalesPlan;
        const newMarkdownPlan = yearlyMarkdowns * (value / 100);

        // update inflow plan
        _formattedData = calcInflowPlanAtIndex(index, _formattedData);

        _formattedData['MarkdownPlan'][index] = Math.round(newMarkdownPlan);
        _formattedData['MarkdownPlanPercent'][index] = PlanService.format(editableData['MarkdownPlanPercent'][index]);

        setFormattedData(_formattedData);
    }

    const handleInventoryBomPlanBlur = (e, rowName, index) => {
        if (!checkValidChange(e.target.value, editableData, setEditableData, formattedData, rowName, index)) return;

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        // take new inventory bom plan, update plan stock to sales and plan inflow as well as turn
        const newInventoryBomPlan = PlanService.format(e.target.value);
        const salesPlan = formattedData['SalesPlan'][index] ?? 0;

        const newStockToSalesPlan = salesPlan ? newInventoryBomPlan / salesPlan : 0;
        const nextInventory = isNaN(formattedData['InventoryBomPlan'][index + 1]) ? formattedData['InventoryBomPlan'][0] : formattedData['InventoryBomPlan'][index + 1];
        const markdownPlan = formattedData['MarkdownPlan'][index] ?? 0;
        const newInflowRetailPlan = calcInflow(nextInventory, newInventoryBomPlan, salesPlan, markdownPlan);
        const newInflowCostPlan = newInflowRetailPlan * getCostComplement(formattedData)

        // calculate for index - 1
        const prevIndex = index - 1 < 0 ? 11 : index - 1;
        const prevInventory = isNaN(formattedData['InventoryBomPlan'][prevIndex]) ? null : formattedData['InventoryBomPlan'][prevIndex];
        if (prevInventory != null) {
            const prevSales = formattedData['SalesPlan'][prevIndex] ?? 0;
            const prevMarkdown = formattedData['MarkdownPlan'][prevIndex] ?? 0;
            const prevInflowRetailPlan = calcInflow(newInventoryBomPlan, prevInventory, prevSales, prevMarkdown);
            const prevInflowCostPlan = prevInflowRetailPlan * getCostComplement(formattedData);

            _formattedData['InflowRetailPlan'][prevIndex] = Math.round(prevInflowRetailPlan);
            _formattedData['InflowCostPlan'][prevIndex] = Math.round(prevInflowCostPlan);
        }

        _formattedData['StockToSalesPlan'][index] = parseFloat(newStockToSalesPlan.toFixed(2));
        _editableData['StockToSalesPlan'][index] = newStockToSalesPlan.toFixed(2);
        _editableData['InventoryBomPlan'][index] = newInventoryBomPlan.toString();
        _formattedData['InventoryBomPlan'][index] = Math.round(newInventoryBomPlan);
        _formattedData['InflowRetailPlan'][index] = Math.round(newInflowRetailPlan);
        _formattedData['InflowCostPlan'][index] = Math.round(newInflowCostPlan);

        setFormattedData(_formattedData);
        setEditableData(_editableData);
        calculatePlanTurn(_formattedData, annualValues, setAnnualValues);
    }

    const salesPlanCustomFormat = (value, index) => {
        const actualSalesValue = formattedData['SalesActuals'][index];

        if (!actualSalesValue) {
            return {};
        }

        if (value < actualSalesValue) {
            return index != 0 ? { color: 'red' } : { color: 'red', backgroundColor: '#D8BFD8' };
        }
        return {};
    }

    const lessThanZeroCustomFormat = (value, index) => {
        if (value < 0) {
            return index != 0 ? { color: 'red' } : { color: 'red', backgroundColor: '#D8BFD8' };
        }
        return {};
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="sales data table">
                <TableHead>
                    <TopHeader displayMonths={displayMonths} />
                </TableHead>
                <TableBody>
                    <CustomTableRow rowName="Actual" data={formattedData['SalesActuals']} />
                    <CustomTableRow rowName="Plan" data={formattedData['SalesPlan']} isPlanRow={true} customFormatLogic={salesPlanCustomFormat} />
                    <CustomTableRow rowName="Plan %" data={formattedData['SalesPlanPercent']} editableData={editableData['SalesPlanPercent']} isPlanRow={true} editable={true} handleInputChange={handleInputChange} handleBlur={handleSalesPercentPlanBlur} />
                    <CustomTableRow rowName="Actual %" data={formattedData['SalesActualPercent']} copyActualsToPlan={copyActualsToPlan} dataSection={'Sales'} />

                    <SubHeader subheaderName="Inventory" customColor={"orange"} />
                    <CustomTableRow rowName="Actual Inv BOM" data={formattedData['InventoryBomActuals']} useAvg={true} />
                    <CustomTableRow rowName="Actual S/S" data={formattedData['StockToSalesActual']} useAvg={true} copyActualsToPlan={copyActualsToPlan} dataSection={'Inv'} />
                    {serviceTypeId == 2 ?
                        <>
                            {/* <CustomTableRow rowName="Plan S/S" data={formattedData['StockToSalesPlan']} isPlanRow={true} useAvg={true} />
                            <CustomTableRow rowName="Plan Inv BOM" data={formattedData['InventoryBomPlan']} isPlanRow={true} useAvg={true} /> */}
                        </>
                    : <>
                        <CustomTableRow rowName="Plan S/S" data={formattedData['StockToSalesPlan']} editableData={editableData['StockToSalesPlan']} isPlanRow={true} editable={true} handleInputChange={handleInputChange} handleBlur={handleStockToSalesPlanBlur} useAvg={true} />
                        <CustomTableRow rowName="Plan Inv BOM" data={formattedData['InventoryBomPlan']} editableData={editableData['InventoryBomPlan']} isPlanRow={true} editable={true} handleInputChange={handleInputChange} handleBlur={handleInventoryBomPlanBlur} useAvg={true} customFormatLogic={lessThanZeroCustomFormat} />
                        </>
                    }
                    <CustomTableRow rowName="Plan Inflow Retail" data={formattedData['InflowRetailPlan']} isPlanRow={true} customFormatLogic={lessThanZeroCustomFormat} />
                    <CustomTableRow rowName="Plan Inflow Cost" data={formattedData['InflowCostPlan']} isPlanRow={true} customFormatLogic={lessThanZeroCustomFormat} />
                    <CustomTableRow rowName="Actual Inflow Retail" data={formattedData['InflowRetailActuals']} />
                    <CustomTableRow rowName="Actual Inflow Cost" data={formattedData['InflowCostActuals']} />

                    <SubHeader subheaderName="Markdowns" customColor={"red"} />
                    <CustomTableRow rowName="Actual" data={formattedData['MarkdownActuals']} />
                    <CustomTableRow rowName="Plan" data={formattedData['MarkdownPlan']} isPlanRow={true} />
                    <CustomTableRow rowName="Plan % MD" data={formattedData['MarkdownPlanPercent']} editableData={editableData['MarkdownPlanPercent']} isPlanRow={true} editable={true} handleInputChange={handleInputChange} handleBlur={handleMarkdownPlanPercentBlur} />
                    <CustomTableRow rowName="Actual % MD" data={formattedData['MarkdownActualPercent']} copyActualsToPlan={copyActualsToPlan} dataSection={'MD'} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/**
 * Ensure Annual Form Values are numbers, then update states
 * @param {object} value The value to validate
 * @param {string} searchKey The key to update in planData
 * @param {string} resetValue The value to reset to if validation fails
 * @param {object} annualValues The plan data object (annuals in this case)
 * @param {function} setAnnualValues The function to update planData
 * @param {function} setEditableValue The function to update the editable value
 * @returns 
 */
const validateAnnualFormBlur = ({ value, searchKey, resetValue, annualValues, setAnnualValues, setEditableValue }) => {
    if (isNaN(Number(value))) {
        setEditableValue(resetValue);
        return false;
    }

    let _annualValues = {...annualValues};
    _annualValues[searchKey] = PlanService.format(value);

    setAnnualValues(_annualValues);
    setEditableValue(value);

    return true;
}

const AnnualEditChip = ({ label, value, searchKey, resetValue, annualValues, setAnnualValues, setEditableValue }) => {
    return (
        <Chip
            style={chipsItem}
            size={"small"}
            onClick={() => validateAnnualFormBlur({ value, searchKey, resetValue, annualValues, setAnnualValues, setEditableValue })}
            label={label}
            sx={{ mt: 1, mr: 1 }}
        />
    )
}

const SalesAnnualEdit = ({ setAnnualValues, annualValues }) => {
    const [annualSalesPlanEdit, setAnnualSalesPlanEdit] = useState();

    useEffect(() => {
        setAnnualSalesPlanEdit(annualValues?.annualSalesPlan ?? 0);
    }, [annualValues]);

    return (
        <>
            <Grid key={'sales-annual'} item xs={3}>
                <TextField
                    onChange={(e) => setAnnualSalesPlanEdit(e.target.value)}
                    onBlur={(e) => validateAnnualFormBlur({ value: e.target.value, searchKey: 'annualSalesPlan', resetValue: annualValues.annualSalesPlan, annualValues, setAnnualValues, setEditableValue: setAnnualSalesPlanEdit })}
                    value={annualSalesPlanEdit}
                    label={"Annual Sales"}
                    size="small"
                    fullWidth
                />
                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <AnnualEditChip label={`Linear ${numeral(annualValues?.linearSalesModel).format("0,0.00")}`} value={annualValues?.linearSalesModel} searchKey={'annualSalesPlan'} resetValue={annualValues.annualSalesPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualSalesPlanEdit} />
                    <AnnualEditChip label={`Calv V1 ${numeral(annualValues?.calc1SalesModel).format("0,0.00")}`} value={annualValues?.calc1SalesModel} searchKey={'annualSalesPlan'} resetValue={annualValues.annualSalesPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualSalesPlanEdit} />
                    <AnnualEditChip label={`Calv V3 ${numeral(annualValues?.calc3SalesModel).format("0,0.00")}`} value={annualValues?.calc3SalesModel} searchKey={'annualSalesPlan'} resetValue={annualValues.annualSalesPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualSalesPlanEdit} />
                    <AnnualEditChip label={`LM Plan ${numeral(annualValues?.salesAnnualPlanLM).format("0,0.00")}`} value={annualValues?.salesAnnualPlanLM} searchKey={'annualSalesPlan'} resetValue={annualValues.annualSalesPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualSalesPlanEdit} />
                </Box>
            </Grid>
        </>
    )
}

const MarkdownsAnnualEdit = ({ setAnnualValues, annualValues }) => {
    const [annualMarkdownPlanEdit, setAnnualMarkdownPlanEdit] = useState();

    useEffect(() => {
        setAnnualMarkdownPlanEdit(annualValues?.annualMarkdownsPlan ?? 0);
    }, [annualValues]);

    return (
        <>
            <Grid key={'markdowns-annual'} item xs={3}>
                <TextField
                    onChange={(e) => setAnnualMarkdownPlanEdit(e.target.value)}
                    onBlur={(e) => validateAnnualFormBlur({ value: e.target.value, searchKey: 'annualMarkdownsPlan', resetValue: annualValues.annualMarkdownPlan, annualValues, setAnnualValues, setEditableValue: setAnnualMarkdownPlanEdit })}
                    value={annualMarkdownPlanEdit}
                    label={"Annual Markdowns"}
                    size="small"
                    fullWidth
                />
                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <AnnualEditChip label={`Actual L12 ${numeral(annualValues?.annualMarkdownsL12).format("0,0.00")}`} value={annualValues?.annualMarkdownsL12} searchKey={'annualMarkdownsPlan'} resetValue={annualValues.annualMarkdownPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualMarkdownPlanEdit} />
                </Box>
            </Grid>
        </>
    )
}

const TurnAnnualEdit = ({ setAnnualValues, annualValues }) => {
    const [annualTurnPlanEdit, setAnnualTurnPlanEdit] = useState();

    useEffect(() => {
        setAnnualTurnPlanEdit(annualValues?.annualTurnPlan ?? 0);
    }, [annualValues]);

    return (
        <>
            <Grid key={'turn-annual'} item xs={3}>
                <TextField
                    onChange={(e) => setAnnualTurnPlanEdit(e.target.value)}
                    onBlur={(e) => validateAnnualFormBlur({ value: e.target.value, searchKey: 'annualTurnPlan', resetValue: annualValues.annualTurnPlan, annualValues, setAnnualValues, setEditableValue: setAnnualTurnPlanEdit })}
                    value={annualTurnPlanEdit}
                    label={"Annual Turn"}
                    size="small"
                    fullWidth
                />
                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <AnnualEditChip label={`Actual ${numeral(annualValues?.annualTurnActual).format("0,0.00")}`} value={annualValues?.annualTurnActual} searchKey={'annualTurnPlan'} resetValue={annualValues.annualTurnPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualTurnPlanEdit} />
                    <AnnualEditChip label={`LM ${numeral(annualValues?.turnLm).format("0,0.00")}`} value={annualValues?.turnLm} searchKey={'annualTurnPlan'} resetValue={annualValues.annualTurnPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualTurnPlanEdit} />
                </Box>
            </Grid>
        </>
    )
}

const ImuAnnualEdit = ({ setAnnualValues, annualValues }) => {
    const [annualImuPlanEdit, setAnnualImuPlanEdit] = useState();

    useEffect(() => {
        setAnnualImuPlanEdit(PlanService.format((annualValues?.imuPlan ?? 0)));
    }, [annualValues?.imuPlan]);

    return (
        <>
            <Grid key={'imu-annual'} item xs={3}>
                <TextField
                    onChange={(e) => setAnnualImuPlanEdit(e.target.value)}
                    onBlur={(e) => validateAnnualFormBlur({ value: e.target.value, searchKey: 'imuPlan', resetValue: annualValues.imuPlan, annualValues, setAnnualValues, setEditableValue: setAnnualImuPlanEdit })}
                    value={annualImuPlanEdit}
                    label={"IMU"}
                    size="small"
                    fullWidth
                />
                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <AnnualEditChip label={`Actual ${numeral(annualValues?.imuActual).format("0,0.00")}`} value={annualValues?.imuActual} searchKey={'imuPlan'} resetValue={annualValues.imuPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualImuPlanEdit} />
                    <AnnualEditChip label={`LM ${numeral(annualValues?.imuLM).format("0,0.00")}`} value={annualValues?.imuLM} searchKey={'imuPlan'} resetValue={annualValues.imuPlan} annualValues={annualValues} setAnnualValues={setAnnualValues} setEditableValue={setAnnualImuPlanEdit} />
                </Box>
            </Grid>
        </>
    )
}

function cashMarginDollars(sales, inflowCost) {
    return sales ? PlanService.format((sales - inflowCost)) : 0;
}

// Metric Calculations
function cashMargin(sales, inflowCost) {
    return sales ? PlanService.format((((sales - inflowCost) / sales) * 100)) : 0;
}

function calculateGMROI(sales, markdowns, avgInventory, costComplement) {
    const avgInventoryCost = avgInventory * costComplement;
    const _mmu = calculateMMU(sales, markdowns, costComplement);
    const _grossMargin = sales * (_mmu / 100);

    return avgInventoryCost ? PlanService.format((100 * (_grossMargin) / avgInventoryCost)) : 0;
}

function claculateCMROI(sales, inflowCost, avgInventory, costComplement) {
    const avgInventoryCost = avgInventory * costComplement;
    const _cashMargin = cashMarginDollars(sales, inflowCost);
    return avgInventoryCost ? PlanService.format(((_cashMargin) / avgInventoryCost)) : 0;
}

function calculateMMU(sales, markdowns, costComplement) {
    const imu = 1 - costComplement;
    return sales ? (imu - (markdowns / sales) * costComplement) : 0;
}

function calculateFreshness(inflowRetail, inventoryBom) {
    return inventoryBom ? PlanService.format((100 * inflowRetail / inventoryBom)) : 0;
}

function calculateEfficiency(inventory, inflowRetail, sales) {
    const inventoryPlusInflow = inventory + inflowRetail;

    return inventoryPlusInflow ? PlanService.format((sales / inventoryPlusInflow)) : 0;
}

function safeDivide(a, b) {
    let val = b ? a / b : 0;

    // if not a number or infinity, return 0
    if (isNaN(val) || !isFinite(val)) {
        return 0;
    }
    return val;
}

function handleAnnualSalesUpdate(annualValues, formattedData, setFormattedData, editableData, setEditableData) {
    if (!isNaN(annualValues?.annualSalesPlan)) {
        // update SalesPlan on editable and formatted data as percents of new annual value
        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        _formattedData['SalesPlan'] = formattedData['SalesPlan'].map((val, index) => Math.round((_formattedData['SalesPlanPercent'][index] / 100) * annualValues.annualSalesPlan));

        // calculate new inventory bom plan
        _formattedData['InventoryBomPlan'] = _formattedData['StockToSalesPlan'].map((val, index) => {
            return Math.round(val * _formattedData['SalesPlan'][index]);
        });

        // loop through inv bom again and handle 0 sales months (do this after so we aren't calculating with old numbers)
        for (let i = 0; i < 12; i++) {
            if (_formattedData['SalesPlan'][i] == 0) {
                // use last month inventory - last month sales - last month markdowns
                const lastMonthIndex = i - 1 < 0 ? 11 : i - 1;
                const lastMonthInventory = _formattedData['InventoryBomPlan'][lastMonthIndex];
                const lastMonthSales = _formattedData['SalesPlan'][lastMonthIndex];
                const lastMonthMarkdowns = _formattedData['MarkdownPlan'][lastMonthIndex];
                _formattedData['InventoryBomPlan'][i] = lastMonthInventory - lastMonthSales - lastMonthMarkdowns;
            }
        }

        _editableData['InventoryBomPlan'] = _formattedData['InventoryBomPlan'].map(val => val.toString());

        // update markdown plan
        const totalMarkdowns = annualValues.annualSalesPlan * (annualValues.annualMarkdownsPlan / 100);
        _formattedData['MarkdownPlan'] = _formattedData['MarkdownPlan'].map((val, index) => Math.round(totalMarkdowns * (_formattedData['MarkdownPlanPercent'][index] / 100)));

        // calculate new inflow plans
        _formattedData = calcAllPlanInflows(_formattedData);

        // if any inflows are negative, balance them
        balanceAllInflows(_formattedData, setFormattedData, _editableData, setEditableData);
    }
}

function handleAnnualMarkdownUpdate(annualValues, formattedData, setFormattedData) {
    if (!isNaN(annualValues?.annualMarkdownsPlan)) {
        let _formattedData = {...formattedData};
        
        // update markdown plan
        const totalMarkdowns = annualValues.annualSalesPlan * (annualValues.annualMarkdownsPlan / 100);
        _formattedData['MarkdownPlan'] = _formattedData['MarkdownPlan'].map((val, index) => Math.round(totalMarkdowns * (_formattedData['MarkdownPlanPercent'][index] / 100)));

        // calculate new inflow plans
        _formattedData = calcAllPlanInflows(_formattedData);

        setFormattedData(_formattedData);
    }
}

function handleAnnualTurnUpdate(annualValues, formattedData, setFormattedData, editableData, setEditableData, serviceTypeId) {
    if (!isNaN(annualValues?.annualTurnPlan)) {
        if (serviceTypeId == 2) {
            return;
        }

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        // old turn plan
        const oldTurnPlan = calculatePlanTurn(_formattedData);
        const newTurnPlan = annualValues.annualTurnPlan;
        if (oldTurnPlan === newTurnPlan) {
            return;
        }
        const oldInventoryAvg = _formattedData['InventoryBomPlan'].reduce((a, b) => a + b, 0) / 12;
        const salesAnnual = annualValues.annualSalesPlan;

        // update turn plan
        _formattedData['PlanTurn'] = annualValues.annualTurnPlan;

        // get new inventory values by evenly distributing the difference between old and new turn plan
        const desiredInventoryAvg = salesAnnual / newTurnPlan;
        const diff = desiredInventoryAvg - oldInventoryAvg;
        const newInventoryBomPlan = _formattedData['InventoryBomPlan'].map((val) => {
            if (val + diff < 0) {
                return 0;
            }
            return Math.round(val + diff)
        });

        // update stock to sales
        _formattedData['StockToSalesPlan'] = _formattedData['SalesPlan'].map((val, index) => val ? PlanService.format((newInventoryBomPlan[index] / val)) : 0);
        _editableData['StockToSalesPlan'] = _formattedData['StockToSalesPlan'].map(val => val.toString());

        // update inventory bom plan
        _formattedData['InventoryBomPlan'] = newInventoryBomPlan;
        _editableData['InventoryBomPlan'] = newInventoryBomPlan.map(val => val.toString());

        setFormattedData(_formattedData);
        setEditableData(_editableData);
    }
}

function handleAnnualImuUpdate(annualValues, formattedData, setFormattedData) {
    if (!isNaN(annualValues?.imuPlan)) {
        let _formattedData = {...formattedData};

        // update imu plan
        _formattedData['imuPlan'] = annualValues.imuPlan / 100;

        // update inflow cost with new imu plan
        _formattedData['InflowCostPlan'] = _formattedData['InflowRetailPlan'].map((val, index) => Math.round(val * getCostComplement(_formattedData)));

        setFormattedData(_formattedData);
    }
}

const requiredNonNegativeRows = ['InventoryBomPlan', 'InflowRetailPlan', 'InflowCostPlan'];

function PlanEditTable(props) {
    const {
        nextClass,
        nextLocation,
        onUpdate,
        savingPlan,
        client,
        planId,
        locationClassId,
        epochMonth,
        displayMonths,
        setChartData,
        messageContext,
        setTabs,
        overridePlanData,
        forceReload,
        setForceReload,
    } = props;

    const [formattedData, setFormattedData] = useState([]);
    const [editableData, setEditableData] = useState([]);
    const [annualValues, setAnnualValues] = useState({});
    const [planData, setPlanData] = useState(null);
    const [serviceTypeId, setServiceTypeId] = useState(1);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const copyActualsToPlan = (dataSection) => {
        if (dataSection === 'Sales') {
            copySalesActuals();
        } else if (dataSection === 'MD') {
            copyMarkdownActuals();
        } else if (dataSection === 'Inv') {
            copyInventoryActuals();
        }
    };
    const copySalesActuals = () => {
        if (!formattedData) {
            return;
        }

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        _formattedData['SalesPlan'] = formattedData['SalesActuals'];
        _formattedData['SalesPlanPercent'] = formattedData['SalesActualPercent'];

        _editableData['SalesPlanPercent'] = _formattedData['SalesPlanPercent'].map(val => val.toString());

        setEditableData(_editableData);
        setFormattedData(_formattedData);
    }
    const copyMarkdownActuals = () => {
        if (!formattedData) {
            return;
        }

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        _formattedData['MarkdownPlan'] = formattedData['MarkdownActuals'];
        _formattedData['MarkdownPlanPercent'] = formattedData['MarkdownActualPercent'];

        _editableData['MarkdownPlanPercent'] = _formattedData['MarkdownPlanPercent'].map(val => val.toString());

        setEditableData(_editableData);
        setFormattedData(_formattedData);
    }

    const copyInventoryActuals = () => {
        if (!formattedData) {
            return;
        }

        let _formattedData = {...formattedData};
        let _editableData = {...editableData};

        _formattedData['InventoryBomPlan'] = formattedData['InventoryBomActuals'];
        _formattedData['InflowRetailPlan'] = formattedData['InflowRetailActuals'];
        _formattedData['InflowCostPlan'] = formattedData['InflowCostActuals'];
        _formattedData['StockToSalesPlan'] = formattedData['StockToSalesActual'];

        _editableData['InventoryBomPlan'] = _formattedData['InventoryBomPlan'].map(val => val.toString());
        _editableData['StockToSalesPlan'] = _formattedData['StockToSalesPlan'].map(val => val.toString());

        setEditableData(_editableData);
        setFormattedData(_formattedData);
    }

    useEffect(() => {
        let _formattedData = [];
        let _editableData = [];
        let _annualValues = [];
        let _chartData = new Map();

        if (planData) {
            try {
                // fill with 0s length 3
                const shortArray = Array(3).fill(0);
                const zeroArray = Array(12).fill(0);

                // Setting this up to be usable throughout this planData load block, but only applying
                // it to a place where there was a bug to reduce the number of changes for now
                const checkThenSplit = (planData, key, fallback = zeroArray) => {
                    if (planData[key]) {
                        return planData[key].split(',').map(Number);
                    }
                    return fallback;
                }

                setServiceTypeId(planData?.serviceTypeId);

                _annualValues['annualSalesPlan'] = Math.round(planData?.salesAnnualPlan);
                _annualValues['salesAnnualPlanLM'] = Math.round(planData?.salesAnnualPlanLM);
                _annualValues['linearSalesModel'] = Math.round(planData?.linearSalesModel);
                _annualValues['calc1SalesModel'] = Math.round(planData?.calc1SalesModel);
                _annualValues['calc3SalesModel'] = Math.round(planData?.calc3SalesModel);

                // annual markdowns
                _annualValues['annualMarkdownsPlan'] = Math.round(planData?.annualMarkdownPlan * 100);
                _annualValues['annualMarkdownsL12'] = Math.round(planData?.annualMarkdownPercentL12);

                // annual turn
                _annualValues['annualTurnPlan'] = 0; // calc later
                _annualValues['annualTurnActual'] = PlanService.format(planData?.annualTurn);
                _annualValues['turnLm'] = PlanService.format(planData?.turnLM);

                // annual imu
                _annualValues['imuPlan'] = planData?.imuPlan * 100;
                _annualValues['imuActual'] = planData?.imuActual * 100;
                _annualValues['imuLM'] = planData?.imuPlanLM * 100;

                // Actuals
                _formattedData['SalesActuals'] = planData?.SalesL12.split(',').map(Number).map(Math.round);
                _formattedData['InventoryBomActuals'] = planData?.InventoryL12.split(',').map(Number).map(Math.round);
                _formattedData['InflowRetailActuals'] = planData?.InflowRetailL12.split(',').map(Number).map(Math.round);
                _formattedData['InflowCostActuals'] = planData?.InflowCostL12.split(',').map(Number).map(Math.round);
                _formattedData['MarkdownActuals'] = planData?.MarkdownsL12.split(',').map(Number).map(Math.round);

                // Annual Actuals
                _formattedData['AnnualSalesActual'] = _formattedData['SalesActuals'].reduce((a, b) => a + b, 0);
                _formattedData['AnnualMarkdownActual'] = _formattedData['MarkdownActuals'].reduce((a, b) => a + b, 0);
                _formattedData['AnnualMarkdownsPercent'] = (_formattedData['AnnualMarkdownActual'] / _annualValues['annualSalesPlan']) * 100;
                _formattedData['AnnualTurnActual'] = planData?.annualTurn;

                // Plan
                _formattedData['SalesPlan'] = planData?.SalesPlanN12.split(',').map(Number).map(Math.round);
                if (planData?.serviceTypeId == 2) {
                    _formattedData['InventoryBomPlan'] = zeroArray;
                } else {
                    _formattedData['InventoryBomPlan'] = planData?.InventoryPlanN12.split(',').map(Number).map(Math.round);
                }
                _formattedData['InflowRetailPlan'] = planData?.InflowRetailPlanN12.split(',').map(Number).map(Math.round);
                _formattedData['InflowCostPlan'] = planData?.InflowCostPlanN12.split(',').map(Number).map(Math.round);
                _formattedData['MarkdownPlan'] = planData?.MarkdownsPlanN12.split(',').map(Number).map(Math.round);

                // Last 90 plans
                _formattedData['SalesPlanL90'] = checkThenSplit(planData, 'SalesPlanL90', shortArray);
                _formattedData['InventoryBomPlanL90'] = checkThenSplit(planData, 'InventoryPlanL90', shortArray);
                _formattedData['InflowRetailPlanL90'] = checkThenSplit(planData, 'InflowRetailPlanL90', shortArray);
                _formattedData['InflowCostPlanL90'] = checkThenSplit(planData, 'InflowCostPlanL90', shortArray);
                _formattedData['MarkdownPlanL90'] = checkThenSplit(planData, 'MarkdownsPlanL90', shortArray);

                // Annual Plan
                _formattedData['AnnualMarkdownPlan'] = _formattedData['MarkdownPlan'].reduce((a, b) => a + b, 0);
                _formattedData['AnnualMarkdownsPercentPlan'] = (_formattedData['AnnualMarkdownPlan'] / _formattedData['salesPlanN12']) * 100;
                _formattedData['PlanTurn'] = 0;

                // Percents and Stock To Sales - Plan
                _formattedData['SalesPlanPercent'] = planData?.SalesPercN12.split(',').map(Number).map(val => PlanService.format((val * 100)));
                _formattedData['StockToSalesPlan'] = planData?.serviceTypeId == 2 
                    ? zeroArray
                    : planData?.StockToSalesPlanN12.split(',').map(Number).map(val => PlanService.format(val.toFixed(2)));
                _formattedData['MarkdownPlanPercent'] = planData?.MarkdownPercN12.split(',').map(Number).map(val => PlanService.format((val * 100)));

                // Percents and Stock To Sales - Actual
                _formattedData['SalesActualPercent'] = _formattedData['SalesActuals'].map((val, index) => PlanService.format((safeDivide(val, _formattedData['AnnualSalesActual']) * 100)));
                _formattedData['StockToSalesActual'] = _formattedData['InventoryBomActuals'].map((val, index) => PlanService.format(safeDivide(val, _formattedData['SalesActuals'][index])));
                _formattedData['MarkdownActualPercent'] = _formattedData['MarkdownActuals'].map((val, index) => PlanService.format((safeDivide(val, _formattedData['AnnualMarkdownActual']) * 100)));

                // Fill out copies in editable fields as strings
                _editableData['SalesPlanPercent'] = _formattedData['SalesPlanPercent'].map(val => val.toString());
                _editableData['StockToSalesPlan'] = _formattedData['StockToSalesPlan'].map(val => val.toString());
                _editableData['InventoryBomPlan'] = _formattedData['InventoryBomPlan'].map(val => val.toString());
                _editableData['MarkdownPlanPercent'] = _formattedData['MarkdownPlanPercent'].map(val => val.toString());

                // Include other data
                _formattedData['imuPlan'] = planData?.imuPlan;
                _formattedData['imuActual'] = planData?.imuActual;
                _formattedData['epochMonth'] = planData?.planEpochMonth;
                _formattedData['locationClassId'] = planData?.LocationClassId;
                _formattedData['planVersion'] = planData?.planVersion;
                _formattedData['maxPlanVersion'] = planData?.maxPlanVersion;
                _formattedData['maxPlanVersionFuture'] = planData?.maxPlanVersionFuture;
                _formattedData['trend'] = planData?.trend;
                _formattedData['onOrderRetailCurrent'] = planData?.onOrderRetailCurrent;
                _formattedData['clientClassId'] = planData?.clientClassId;
                _formattedData['clientLocationId'] = planData?.clientLocationId;
                _formattedData['serviceTypeId'] = planData?.serviceTypeId;
                _formattedData['actualMarkdownPercentageLast12m'] = planData?.actualMarkdownPercentageLast12m;
                _formattedData['imuFromImuRef'] = planData?.imuFromImuRef;
                _formattedData['salesMonthModelType'] = planData?.salesMonthModelType;
                _formattedData['inflowMonthModelType'] = planData?.inflowMonthModelType;
                _formattedData['inventoryModelType'] = planData?.inventoryModelType;
                _formattedData['openValuesJson'] = planData?.openValuesJsonN12?.split('||| ')

                // get this month actuals
                _formattedData['actualsThisMonth'] = {
                    SalesActual: planData?.SalesActualThisMonth,
                    InventoryBomActual: planData?.InventoryActualThisMonth,
                    InflowRetailActual: planData?.InflowRetailActualThisMonth,
                    InflowCostActual: planData?.InflowCostActualThisMonth,
                    MarkdownActual: planData?.MarkdownsActualThisMonth,
                    InventoryCurrent: planData?.InventoryCurrent,
                }

                // override inventory actual 0 to be current month bom
                _formattedData['InventoryBomActuals'][0] = Math.round(_formattedData['actualsThisMonth']['InventoryBomActual']);

                // build out chart data to contain SalesPlan, InventoryBomPlan, MarkdownsPlan, InflowPlanRetail
                _chartData.set('SalesPlan', _formattedData['SalesPlan']);
                _chartData.set('InventoryBomPlan', _formattedData['InventoryBomPlan']);
                _chartData.set('MarkdownsPlan', _formattedData['MarkdownPlan']);
                _chartData.set('InflowPlanRetail', _formattedData['InflowRetailPlan']);

                _annualValues['annualTurnPlan'] = calculatePlanTurn(_formattedData);
                _formattedData['PlanTurn'] = _annualValues['annualTurnPlan'];
            } catch (e) {
                console.error('Error parsing plan data', e);

                console.info(planData);

                messageContext.show("Failed to parse plan data. Plan tables may be missing.");
                return;
            }
        }

        setFormattedData(_formattedData);
        setEditableData(_editableData);
        setAnnualValues({..._annualValues});
        setChartData(_chartData);
    }, [planData]);

    useEffect(() => {
        if (overridePlanData && planData) {
            let _planData = {...planData};

            // copy over only stock to sales, markdown monthly plan percent, and sales monthly plan percent
            _planData['StockToSalesPlanN12'] = overridePlanData['StockToSalesPlanN12'];
            _planData['MarkdownPercN12'] = overridePlanData['MarkdownPercN12'];
            _planData['SalesPercN12'] = overridePlanData['SalesPercN12'];

            // calculate salesPlanN12, inventoryPlanN12, InflowRetailPlanN12, inflowPlanCostN12, markdownsPlanN12
            _planData['SalesPlanN12'] = overridePlanData['SalesPercN12'].split(',').map(Number).map((val, index) => val * _planData['salesAnnualPlan']);
            _planData['InventoryPlanN12'] = overridePlanData['StockToSalesPlanN12'].split(',').map(Number).map((val, index) => Math.round(val * _planData['SalesPlanN12'][index]));
            _planData['InflowRetailPlanN12'] = overridePlanData['InflowRetailPlanN12'].split(',').map(Number).map((val, index) => Math.round(val));
            _planData['InflowCostPlanN12'] = _planData['InflowRetailPlanN12'].map((val, index) => Math.round(val * getCostComplement(_planData)));
            _planData['MarkdownsPlanN12'] = overridePlanData['MarkdownPercN12'].split(',').map(Number).map((val, index) => val * _planData['annualMarkdownsPlanN12']);

            // convert all of those to comma separated strings
            _planData['SalesPlanN12'] = _planData['SalesPlanN12'].join(',');
            _planData['InventoryPlanN12'] = _planData['InventoryPlanN12'].join(', ');
            _planData['InflowRetailPlanN12'] = _planData['InflowRetailPlanN12'].join(', ');
            _planData['InflowCostPlanN12'] = _planData['InflowCostPlanN12'].join(', ');
            _planData['MarkdownsPlanN12'] = _planData['MarkdownsPlanN12'].join(', ');

            // overrides the rest of planData with the data from the override while keeping the basic info for updating
            setPlanData(_planData);
        }
    }, [overridePlanData])

    useEffect(() => {
        if (formattedData['InflowCostActuals'] && formattedData['InflowCostPlan']) {
            const displayInThousands = false; //formattedData['InventoryBomPlan'][0] >= 10000;

            const handleConversion = (val) => {
                return displayInThousands ? numeral(val / 1000).format("0.0a") + 'k' : numeral(val).format("0,0");
            }

            let _tabs = [];

            const actualSalesLast12 = formattedData['SalesActuals'].reduce((a, b) => a + b, 0);
            const actualInflowCost = formattedData['InflowCostActuals'].reduce((a, b) => a + b, 0);
            const actualInflowRetail = formattedData['InflowRetailActuals'].reduce((a, b) => a + b, 0);
            const planInflowCost = formattedData['InflowCostPlan'].reduce((a, b) => a + b, 0);
            const planInflowRetail = formattedData['InflowRetailPlan'].reduce((a, b) => a + b, 0);
            const inventoryBomActualAvg = formattedData['InventoryBomActuals'].reduce((a, b) => a + b, 0) / 12;
            const inventoryBomPlanAvg = formattedData['InventoryBomPlan'].reduce((a, b) => a + b, 0) / 12;
            const annualSalesPlan = formattedData['SalesPlan'].reduce((a, b) => a + b, 0);

            _tabs['Targets'] = {
                cash: {
                    actual: cashMargin(formattedData['AnnualSalesActual'], actualInflowCost),
                    plan: cashMargin(annualSalesPlan, planInflowCost),
                },
                GMROI: {
                    actual: calculateGMROI(formattedData['AnnualSalesActual'], formattedData['AnnualMarkdownActual'], inventoryBomActualAvg, getCostComplement(formattedData, true)),
                    plan: calculateGMROI(annualSalesPlan, formattedData['AnnualMarkdownPlan'], inventoryBomPlanAvg, getCostComplement(formattedData)),
                },
                CMROI: {
                    actual: claculateCMROI(formattedData['AnnualSalesActual'], actualInflowCost, inventoryBomActualAvg, getCostComplement(formattedData, true)),
                    plan: claculateCMROI(annualSalesPlan, planInflowCost, inventoryBomPlanAvg, getCostComplement(formattedData)),
                },
                MMU: {
                    actual: parseFloat((calculateMMU(formattedData['AnnualSalesActual'], formattedData['AnnualMarkdownActual'], getCostComplement(formattedData, true)) * 100).toFixed(2)),
                    plan: parseFloat((calculateMMU(annualSalesPlan, formattedData['AnnualMarkdownPlan'], getCostComplement(formattedData)) * 100).toFixed(2)),
                },
                Efficiency: {
                    actual: calculateEfficiency(inventoryBomActualAvg, actualInflowRetail, formattedData['AnnualSalesActual']),
                    plan: calculateEfficiency(inventoryBomPlanAvg, planInflowRetail, annualSalesPlan),
                },
            }

            const trend = formattedData['trend'] ?? 0;
            const salesTrend = trend != 0 ? formattedData['actualsThisMonth']['SalesActual'] / trend : 0;
            const markdownTrend = trend != 0 ? formattedData['actualsThisMonth']['MarkdownActual'] / trend : 0;
            const salesRemaining = salesTrend - formattedData['actualsThisMonth']['SalesActual'];
            const markdownsRemaining = markdownTrend - formattedData['actualsThisMonth']['MarkdownActual'];
            const inventoryTrend = formattedData['actualsThisMonth']['InventoryBomActual'] - salesRemaining - markdownsRemaining + formattedData['onOrderRetailCurrent'];

            _tabs['Trend'] = {
                sales: {
                    actual: handleConversion(formattedData['actualsThisMonth']['SalesActual']),
                    trend: handleConversion(salesTrend),
                    plan: handleConversion(formattedData['SalesPlan'][0]),
                    percentOfPlan: formattedData['SalesPlan'] ? (salesTrend / formattedData['SalesPlan'][0] * 100).toFixed(0) + '%' : 0,
                },
                markdowns: {
                    actual: handleConversion(formattedData['actualsThisMonth']['MarkdownActual']),
                    trend: handleConversion(markdownTrend),
                    plan: handleConversion(formattedData['MarkdownPlan'][0]),
                    percentOfPlan: formattedData['MarkdownPlan'] ? (markdownTrend / formattedData['MarkdownPlan'][0] * 100).toFixed(0) + '%' : 0,
                },
                inventory: {
                    actual: handleConversion(formattedData['actualsThisMonth']['InventoryCurrent']),
                    trend: handleConversion(inventoryTrend),
                    plan: handleConversion(formattedData['InventoryBomPlan'][0]),
                    percentOfPlan: formattedData['InventoryBomPlan'] ? (inventoryTrend / formattedData['InventoryBomPlan'][0] * 100).toFixed(0) + '%' : 0,
                },
                calc: {
                    actual: '',
                    trend: '',
                    plan: handleConversion(annualValues?.calc1SalesModel),
                    percentOfPlan: '',
                },
                calc3: {
                    actual: '',
                    trend: '',
                    plan: handleConversion(annualValues?.calc3SalesModel),
                    percentOfPlan: '',
                },
                linear: {
                    actual: '',
                    trend: '',
                    plan: handleConversion(annualValues?.linearSalesModel),
                    percentOfPlan: '',
                },
                L12: {
                    actual: '',
                    trend: Math.round(actualSalesLast12 - formattedData['SalesActuals'][0] + salesTrend), // last 11 months plus sales trend,
                    plan: '',
                    percentOfPlan: '',
                },
            }

            let inflowActual = formattedData['InflowRetailActuals'][11];
            let inflowPlan = formattedData['InflowRetailPlanL90'][0];
            let inflowActualCost = formattedData['InflowCostActuals'][11];
            let inflowPlanCost = formattedData['InflowCostPlanL90'][0];
            let salesActual = formattedData['SalesActuals'][11];
            let salesPlan = formattedData['SalesPlanL90'][0];
            let markdownsActual = formattedData['MarkdownActuals'][11];
            let markdownsPlan = formattedData['MarkdownPlanL90'][0];
            let cashActual;
            let cashPlan;
            let tabName = '';
            let inventoryBom = formattedData['actualsThisMonth']['InventoryBomActual'];
            let inventoryBomPlan = formattedData['InventoryBomPlan'][0];

            for (let i = 1; i <= 3; i++) {
                tabName = i * 30 + '';

                if (i != 1) {
                    inflowActual += formattedData['InflowRetailActuals'][12 - i] ?? 0;
                    inflowPlan += formattedData['InflowRetailPlanL90'][i - 1] ?? 0;
                    salesActual += formattedData['SalesActuals'][12 - i] ?? 0;
                    salesPlan += formattedData['SalesPlanL90'][i - 1] ?? 0;
                    markdownsActual += formattedData['MarkdownActuals'][12 - i] ?? 0;
                    markdownsPlan += formattedData['MarkdownPlanL90'][i - 1] ?? 0;
                    inflowActualCost += formattedData['InflowCostActuals'][12 - i] ?? 0;
                    inflowPlanCost += formattedData['InflowCostPlanL90'][i - 1] ?? 0;

                    // inventoryBom = formattedData['InventoryBomActuals'][12 - i] ?? 0;
                    // inventoryBomPlan = formattedData['InventoryBomPlanL90'][i - 1] ?? 0;
                }

                cashActual = salesActual - inflowActualCost;
                cashPlan = salesPlan - inflowPlanCost;

                _tabs[tabName] = {
                    cash: {
                        actual: cashActual,
                        plan: cashPlan,
                    },
                    inflow: {
                        actual: inflowActual,
                        plan: inflowPlan,
                    },
                    sales: {
                        actual: salesActual,
                        plan: salesPlan,
                    },
                    markdowns: {
                        actual: markdownsActual,
                        plan: markdownsPlan,
                    },
                    freshness: {
                        actual: calculateFreshness(inflowActual, inventoryBom),
                        plan: calculateFreshness(inflowPlan, inventoryBomPlan),
                    }
                }
            }

            setTabs(_tabs);
        }
    }, [formattedData]);

    // Handle update to editable data to update chart data
    useEffect(() => {
        let _chartData = new Map();
        _chartData.set('SalesPlan', formattedData['SalesPlan']);
        _chartData.set('InventoryBomPlan', formattedData['InventoryBomPlan']);
        _chartData.set('MarkdownsPlan', formattedData['MarkdownPlan']);
        _chartData.set('InflowPlanRetail', formattedData['InflowRetailPlan']);

        setChartData(_chartData);

        let _saveDisabled = false;
        for (let i = 0; i < requiredNonNegativeRows.length; i++) {
            if (formattedData[requiredNonNegativeRows[i]] && formattedData[requiredNonNegativeRows[i]].some(val => val < 0)) {
                _saveDisabled = true;
                break;
            }
        }

        setSaveDisabled(_saveDisabled);
    }, [formattedData]);

    // Handle change of Sales Annual Value
    useEffect(() => {
        handleAnnualSalesUpdate(annualValues, formattedData, setFormattedData, editableData, setEditableData);
    }, [annualValues?.annualSalesPlan]);

    // Handle change of Markdowns Annual Value
    useEffect(() => {
        handleAnnualMarkdownUpdate(annualValues, formattedData, setFormattedData);
    }, [annualValues?.annualMarkdownsPlan]);

    // Handle change of Turn Annual Value
    useEffect(() => {
        handleAnnualTurnUpdate(annualValues, formattedData, setFormattedData, editableData, setEditableData, serviceTypeId);
    }, [annualValues?.annualTurnPlan]);

    // Handle change of IMU Annual Value
    useEffect(() => {
        handleAnnualImuUpdate(annualValues, formattedData, setFormattedData);
    }, [annualValues?.imuPlan]);

    async function fetchData() {
        setPlanData(null);
        setFormattedData([]);
        setEditableData([]);
        setAnnualValues({});
        setChartData(new Map());
        setTabs({});

        let data = await PlanService.getLivePlanDetailV2(
            client.Id,
            planId,
            epochMonth,
            locationClassId,
        );

        if (data)
            setPlanData(data[0]);
        else
            messageContext.show("Failed to fetch plan data. Data likely missing or malformed.");
    }

    useEffect(async () => {
        if (planId && locationClassId && epochMonth)
            fetchData();
    }, [planId]);

    useEffect(() => {
        if (forceReload) {
            fetchData();
            setForceReload(false);
        }
    }, [forceReload]);

    // if service type id is 2, make sure inventory bom plan and stock to sales plan are 0s
    useEffect(() => {
        if (serviceTypeId == 2 && formattedData?.InventoryBomPlan && formattedData?.StockToSalesPlan) {
            let _formattedData = {...formattedData};
            let _editableData = {...editableData};

            // if any of the inventory plan data is not 0, set it to 0
            let hasUpdate = false;
            if (_formattedData['InventoryBomPlan'].some(val => val != 0)) {
                _formattedData['InventoryBomPlan'] = Array(_formattedData['InventoryBomPlan'].length).fill(0);
                _editableData['InventoryBomPlan'] = _formattedData['InventoryBomPlan'].map(val => val.toString());

                hasUpdate = true;
            }

            // if any of the stock to sales plan data is not 0, set it to 0
            if (_formattedData['StockToSalesPlan'].some(val => val != 0)) {
                _formattedData['StockToSalesPlan'] = Array(_formattedData['StockToSalesPlan'].length).fill(0);
                _editableData['StockToSalesPlan'] = _formattedData['StockToSalesPlan'].map(val => val.toString());
                
                hasUpdate = true;
            }

            if (hasUpdate) {
                setFormattedData(_formattedData);
                setEditableData(_editableData);
            }
        }
    }, [formattedData]);

    const copyActuals = () => {

        copySalesActuals();
        copyInventoryActuals();
        copyMarkdownActuals();

        // TODO: Determine if we need to update any annual fields
    }

    // const [rowVisibility, setRowVisibility] = useState({
    //     switchSales: false,
    //     switchInventory: false,
    //     switchMarkdowns: false,
    // });
    // const ToggleSwitch = ({ switchKey }) => (
    //     <Tooltip title="Toggle Historical Data">
    //         <Switch checked={rowVisibility[switchKey]} size={'small'} onChange={() => toggleRowVisibility(switchKey)} />
    //     </Tooltip>
    // );
    // const toggleRowVisibility = (key) => {
    //     setRowVisibility({
    //     ...rowVisibility,
    //     [key]: !rowVisibility[key],
    //     });
    // };
    
    if (!formattedData || !formattedData['SalesPlan']) {
        return <div>Loading...</div>
    }

    return (
        <>
            <Box sx={rightSection}>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    {/*<Grid item xs={3}>*/}
                    {/*    <Button*/}
                    {/*        onClick={copyActuals}*/}
                    {/*        variant="contained"*/}
                    {/*        color="primary"*/}
                    {/*        size="small"*/}
                    {/*        fullWidth*/}
                    {/*    >*/}
                    {/*        Copy Actuals*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                    <Grid item xs={3}>
                        <Button
                            onClick={() => {
                                if (editableData && formattedData) {
                                    balanceAllInflows(formattedData, setFormattedData, editableData, setEditableData);
                                }
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            fullWidth
                        >
                            Balance all Inflow
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            onClick={() => {
                                if (editableData && formattedData) {
                                    simpleKFlow(formattedData, setFormattedData, editableData, setEditableData, annualValues, setAnnualValues)
                                }
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            fullWidth
                        >
                            Simple K Flow
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={rightSection}>
                {annualValues ? 
                    <>
                        <Grid container spacing={2} sx={{ width: '100%' }}>
                            <SalesAnnualEdit setAnnualValues={setAnnualValues} annualValues={annualValues} />
                            <MarkdownsAnnualEdit setAnnualValues={setAnnualValues} annualValues={annualValues} />
                            <TurnAnnualEdit setAnnualValues={setAnnualValues} annualValues={annualValues} />
                            <ImuAnnualEdit setAnnualValues={setAnnualValues} annualValues={annualValues} />
                        </Grid>
                    </>
                    : null
                }
            </Box>
            <Paper>
                <PlanTable
                    formattedData={formattedData}
                    setFormattedData={setFormattedData}
                    editableData={editableData}
                    setEditableData={setEditableData}
                    annualValues={annualValues}
                    displayMonths={displayMonths}
                    setAnnualValues={setAnnualValues}
                    serviceTypeId={serviceTypeId}
                    copyActualsToPlan={copyActualsToPlan}
                />
            </Paper>
            <Box
                sx={{
                    py: 1,
                    pl: 1,
                    position: "fixed",
                    bottom: 0,
                    backgroundColor: "#f3f4f5",
                    borderRadius: "5px 5px 0 0",
                    boxShadow: "0 -1px 5px rgb(162 166 187 / 40%)",
                }}
            >
                <LoadingButton
                    loading={savingPlan}
                    onClick={(e) =>
                        onUpdate(formattedData, annualValues)
                    }
                    variant="contained"
                    disabled={savingPlan || saveDisabled}
                    sx={actionButton}
                >
                    UPDATE PLAN
                </LoadingButton>
                <LoadingButton
                    loading={savingPlan}
                    onClick={(e) =>
                        onUpdate(formattedData, annualValues, true)
                    }
                    variant="contained"
                    disabled={savingPlan || saveDisabled}
                    sx={actionButton}
                >
                    UPDATE PLAN AS FUTURE
                </LoadingButton>
                <Button
                    onClick={nextLocation}
                    variant="outlined"
                    sx={actionButton}
                >
                    NEXT LOCATION
                </Button>
                <Button
                    onClick={() => {nextLocation({ forward: false })}}
                    variant="outlined"
                    sx={actionButton}
                >
                    PREV LOCATION
                </Button>
                <Button
                    onClick={nextClass}
                    variant="outlined"
                    sx={actionButton}
                >
                    NEXT CLASS
                </Button>
                <Button
                    onClick={() => {nextClass({ forward: false })}}
                    variant="outlined"
                    sx={actionButton}
                >
                    PREV CLASS
                </Button>
            </Box>
            <HotKeysDrawer />
        </>
    );
}
export default PlanEditTable;
